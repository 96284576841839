
import { Grid, Card, Typography, CardContent, TextField, Button, CircularProgress, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import { useState, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

export default function CategoriesForm({ usuario }) {

    const [categoria, SetCategoria] = useState({
        'cat_id': '',
        'name': '',
        'type': ''
    });

    //const [ultimacategoria, SetUltimaCategoria] = useState({
    //     'max': ''
    //});


    const [cargando, SetCargando] = useState(false);
    const [editando, SetEditando] = useState(false);
    const [tiposdecategoria, SetTiposDeCategoria] = useState([]);
    const [tieneidiomas, SetTieneIdiomas] = useState(false);
    const navigate = useNavigate()
    const params = useParams()

    const [idiomas, SetIdiomas] = useState([
        {
            cat_id: params.id ? params.id : categoria.cat_id,
            language: "ES",
            value: "Ingrese traducción",
            state: "pending",
            username: usuario.username
        },
        {
            cat_id: params.id ? params.id : categoria.cat_id,
            language: "EN",
            value: "Ingrese traducción",
            state: "pending",
            username: usuario.username
        },
        {
            cat_id: params.id ? params.id : categoria.cat_id,
            language: "PO",
            value: "Ingrese traducción",
            state: "pending",
            username: usuario.username
        }
    ]);


    const camposIdiomaCompletados = () => {
        return idiomas.every(idioma => idioma.value !== "Ingrese traducción");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log('submit')
        //console.log(categoria)

        SetCargando(true)

        if (editando) {


            console.log('Editado')
            try {
                //HAGO EL POST Y RECIBO LA RESPUESTA EN RES PARA ACTUALIZAR UNA categoria
                const res = await fetch(process.env.REACT_APP_API_URL + `/categoriesUpdate?id=${params.id}`,
                    {
                        method: "POST",
                        body: JSON.stringify(categoria),
                        mode: "cors",
                        headers: { "Content-Type": "application/json" }
                    }
                )
                const data = await res.json()
                console.log(data)
            } catch (error) {

                console.log(error)

            }
        } else {

            //HAGO EL POST Y RECIBO LA RESPUESTA EN RES PARA CREAR UNA categoria

            try {
                console.log(categoria)
                const res = await fetch(process.env.REACT_APP_API_URL + `/categoriesAdd?username=${usuario.username}&state=pending`, {
                    method: "POST",
                    body: JSON.stringify(categoria),
                    mode: "cors",
                    headers: { "Content-Type": "application/json" }
                });

                if (!res.ok) {
                    throw new Error(`HTTP error! status: ${res.status}`);
                }

                const data = await res.json();
                console.log('Success:', data);
                // Maneja la respuesta exitosa aquí

            } catch (error) {
                console.error('Error:', error);
                // Maneja los errores aquí
            }

        }

        //SI NO TENIA IDIOMAS LOS CREO AHORA!

        if (!tieneidiomas) {
            console.log("no tiene idiomas, hay que crearlos");

            for (const idioma of idiomas) {

                try {
                    const res = await fetch(
                        `${process.env.REACT_APP_API_URL}/categoriestranslationscreateone?username=${usuario.username}`,
                        {
                            method: "POST",
                            body: JSON.stringify(idioma),
                            mode: "cors",
                            headers: { "Content-Type": "application/json" },
                        }
                    );
                    const data = await res.json();
                    console.log(data); // Puedes manejar la respuesta aquí

                } catch (error) {

                    console.log(error)

                }
            }
        }

        //TERMINO EL PROCESO 
        SetCargando(false)

        //LO ENVIO AL INICIO 
        navigate('/home/')

    }

    const handleOnChange = e => {
        //  console.log(e.target.name, e.target.value)
        SetCategoria({ ...categoria, [e.target.name]: e.target.value })
    }


    const handleSubmitIdioma = async (idioma) => {
        //  idioma.preventDefault();

        console.log("Actualizando idioma...")
        console.log(idioma)

        const confirmed = window.confirm("¿Estás seguro de que deseas actualizar el idioma de esta categoria?");
        if (!confirmed) {
            return; // Si el usuario cancela, no ejecuta el delete
        }

        try {
            const res = await fetch(process.env.REACT_APP_API_URL + `/categoriestranslationsupdateone?username=${usuario.username}`, {
                method: "POST",
                body: JSON.stringify(idioma),
                mode: "cors",
                headers: { "Content-Type": "application/json" },
            });
        } catch (error) {

            console.log(error)

        }

    }

    const handleOnChangeidiomas = (e, index) => {
        const { name, value } = e.target;

        // Crear una copia de `idiomas` y actualizar el idioma específico
        const newIdiomas = [...idiomas];
        newIdiomas[index] = {
            ...newIdiomas[index],
            [name]: value
        };

        // Actualizar el estado con la copia modificada
        SetIdiomas(newIdiomas);
    };


    //// AGREGO CODIGO PARA CUANDO VENGO A EDITAR USANDO EL MISMO FORM DE CREAR

    const cargarCategoria = async (id) => {

        try {
            const res = await fetch(process.env.REACT_APP_API_URL + `/categoriesOne?id=${id}`)
            const data = await res.json()
            console.log(data[0])

            SetCategoria({ cat_id: data[0].cat_id, name: data[0].name, type: data[0].type })
            TextField.type = data[0].type
            SetEditando(true)
        } catch (error) {

            console.log(error)

        }

    }

    const buscarTiposDeCategoria = async () => {
        try {
            const res = await fetch(process.env.REACT_APP_API_URL + `/categoriesTypes/`)
            const data = await res.json()
            console.log(data)
            SetTiposDeCategoria(data)
        } catch (error) {

            console.log(error)

        }

        //  console.log(tiposdecategoria)
    }

    const zeroPad = (num, places) => String(num).padStart(places, '0')

    const cargarUltimaCategoria = async () => {
        try {
            const res = await fetch(process.env.REACT_APP_API_URL + `/categoriesLast/`)
            const data = await res.json()

            console.log(data[0].max)
            let dato = data[0].max.split("_")
            console.log(dato[1])
            let numeroultimo = parseInt(dato[1]) + 1
            console.log(numeroultimo)
            numeroultimo = zeroPad(numeroultimo, 6)
            numeroultimo = 'CAT_' + numeroultimo
            TextField.cat_id = numeroultimo
            TextField.type = 'diagnostic'

            //SetUltimaCategoria(data)

            SetCategoria({ cat_id: numeroultimo, type: "system" })

            SetIdiomas([
                {
                    cat_id: numeroultimo,
                    language: "ES",
                    value: "Ingrese traducción",
                    state: "pending",
                    username: usuario.username
                },
                {
                    cat_id: numeroultimo,
                    language: "EN",
                    value: "Ingrese traducción",
                    state: "pending",
                    username: usuario.username
                },
                {
                    cat_id: numeroultimo,
                    language: "PO",
                    value: "Ingrese traducción",
                    state: "pending",
                    username: usuario.username
                }
            ])
            console.log(idiomas)

        } catch (error) {

            console.log(error)

        }

    }

    //PIDO LOS DATOS AL BACKEND
    const loadIdiomas = async (id) => {

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + `/categoriestranslationsgetone?cat_id=${id}`)
            const data = await response.json()
            //console.log(data)

            // Solo actualiza el estado si `data` no está vacío
            if (data && data.length > 0) {
                SetIdiomas(data);
                console.log("Idiomas cargados:", data);
                SetTieneIdiomas(true);
            } else {
                console.log("La consulta de idiomas está vacía, no se actualiza el estado.");

            }

            console.log(data)
            console.log(idiomas)
        } catch (error) {

            console.log(error)

        }

    }


    useEffect(() => {
        if (usuario.type === '') {
            navigate("/");
        }
        buscarTiposDeCategoria();
        //si viene con algun id..
        if (params.id) {
            // console.log('viene con un dato de categoria a editar')
            cargarCategoria(params.id)
            loadIdiomas(params.id)
        }
        //voy a crear uno nuevo cargo la ultima para poder crear una nueva
        else {
            cargarUltimaCategoria();

        }
    }, [params.id])




    return (

        <Grid container direction={'column'} alignContent={'center'} justifyContent={'center'} >
            <Grid item xs={8}>
                <Card
                    xs={8}
                    sx={{ mt: 5, width: 550 }}
                    style={{
                        backgroundColor: '#bdbdbd',
                        padding: '1 rem'
                    }}
                >
                    <Typography variant='h6' textAlign='center' >
                        {editando ? "Editar Categoría" : "Crear Nueva Categoría"}

                    </Typography>
                    <CardContent >
                        <form onSubmit={handleSubmit}  >
                            <TextField
                                variant='filled'
                                label='Nombre de la categoría'
                                name='name'
                                value={categoria.name}
                                onChange={handleOnChange}
                                sx={{ display: 'flex', margin: '.5rem 0' }}
                                inputprops={{ style: { color: 'white' } }}
                                inputlabelprops={{ style: { color: 'white' } }}
                            />
                            <TextField
                                variant='standard'
                                label='Código Categoría (generado por el sistema)'
                                name='cat_id'
                                disabled={true}
                                // {  params.id ?  'value = {categoria.cat_id}'  :  'value = {ultimacategoria.max}' }
                                //   value={ ultimacategoria.max  }
                                value={categoria.cat_id}
                                // value={ (params.id) ? {categoria.cat_id} : {ultimacategoria.max}  }
                                onChange={handleOnChange}
                                sx={{ display: 'block', margin: '.5rem 0' }}
                                inputprops={{ style: { color: 'white' } }}
                                inputlabelprops={{ style: { color: 'white' } }}
                            />

                            {
                                (tiposdecategoria.length !== 0) ?
                                    <FormControl fullWidth>
                                        <InputLabel id="Tipo">Tipo</InputLabel>
                                        <Select
                                            //labelId="demo-simple-select-label"
                                            //id="demo-simple-select"
                                            label="Tipo"
                                            name='type'
                                            value={categoria.type}
                                            onChange={handleOnChange}
                                            inputprops={{ style: { color: 'white' } }}
                                            inputlabelprops={{ style: { color: 'white' } }}
                                        >

                                            {
                                                tiposdecategoria.map(tipo => (
                                                    <MenuItem
                                                        inputprops={{ style: { color: 'white' } }}
                                                        inputlabelprops={{ style: { color: 'white' } }}
                                                        key={tipo.type}
                                                        value={tipo.type}>{tipo.type}</MenuItem>

                                                )
                                                )

                                            }

                                        </Select>
                                    </FormControl>
                                    :
                                    "Cargando"
                            }

                            <Button
                                variant='contained'
                                color='primary'
                                type='submit'
                                sx={{ display: "block", margin: ".5rem auto" }}
                                disabled={!categoria.cat_id || !categoria.name || !categoria.type || cargando || !camposIdiomaCompletados()}
                            >
                                {cargando ? < CircularProgress
                                    color='inherit'
                                    size={24}

                                /> : 'Guardar'}

                            </Button>

                            {!camposIdiomaCompletados() && (
                                <Typography variant="body2" color="error" textAlign="center" sx={{ marginTop: ".5rem" }}>
                                    Por favor, complete todas las traducciones antes de guardar.
                                </Typography>
                            )}

                        </form>

                        <br></br>
                        <Typography variant="h6" textAlign="center">
                            Idiomas:
                        </Typography>
                        <br></br>

                        {

                            (idiomas.length !== 0) ?


                                idiomas.map((idioma, index) => (
                                    <Card
                                        style={
                                            {
                                                marginBottom: '.3rem',
                                                backgroundColor: '#bdbdbd'
                                            }
                                        }
                                        key={idioma.language}
                                    >
                                        <CardContent style={{
                                            display: 'flex',
                                            justifyContent: 'space-between'
                                        }}>
                                            <div style={{ color: 'white' }}>

                                                <Grid container spacing={3}>
                                                    <Grid item xs={3}> {/* Ocupa menos espacio */}

                                                        <TextField
                                                            variant="filled"
                                                            label="Idioma"
                                                            name="language"
                                                            value={idioma.language}
                                                            disabled={true}
                                                            onChange={(e) => handleOnChangeidiomas(e, index)}
                                                            sx={{ display: "flex", margin: ".5rem 0" }}
                                                            inputprops={{ style: { color: "white" } }}
                                                            inputlabelprops={{ style: { color: "white" } }}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={9}> {/* Ocupa más espacio */}
                                                        <TextField
                                                            variant="filled"
                                                            label="Traducción"
                                                            name="value"
                                                            value={idioma.value}
                                                            onChange={(e) => handleOnChangeidiomas(e, index)}
                                                            sx={{ display: "flex", margin: ".5rem 0", width: "100%" }}
                                                            inputprops={{ style: { color: "white" } }}
                                                            inputlabelprops={{ style: { color: "white" } }}
                                                        />
                                                    </Grid></Grid>

                                                {(tieneidiomas) ?
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        type="submit"
                                                        onClick={() => handleSubmitIdioma(idioma)}
                                                        sx={{ display: "block", margin: ".5rem auto" }}
                                                        disabled={!idioma.language || !idioma.value}
                                                    >
                                                        {cargando ? (
                                                            <CircularProgress color="inherit" size={24} />
                                                        ) : (
                                                            "Actualizar idioma"
                                                        )}
                                                    </Button>
                                                    :
                                                    ''
                                                }
                                            </div>
                                        </CardContent>
                                    </Card >
                                )

                                )

                                :
                                <Grid container spacing={3}>
                                    <Grid item xs={1}>

                                    </Grid>

                                    <Grid item xs={10} >
                                        <h4>No se han cargado idiomas para este síntoma.</h4>


                                    </Grid>

                                    <Grid item xs={1} >

                                    </Grid>
                                </Grid>

                        }


                    </CardContent>

                </Card>
            </Grid>
        </Grid>
    )
}
