import { useEffect, useState } from "react"
import { Button, Card, CardContent, Typography, Grid, Snackbar, Alert } from '@mui/material'
import { useNavigate } from 'react-router-dom';

export default function SymptomsListPending({ usuario }) {

  const [sintomas, SetSintomas] = useState([])
  const [sintomascount, SetSintomasCount] = useState({
    'count': '0'
  });

  const navigate = useNavigate();

  // Snackbar state
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  // Función para cerrar el Snackbar
  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };


  //PIDO LOS DATOS AL BACKEND
  const loadSymptomsPending = async () => {

    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/symptomsPending')
      const data = await response.json()
      // console.log(data)
      SetSintomas(data)
    } catch (error) {

      //console.log(error)
      console.log(error.message)
    }

  }

  const loadSymptomsCountPending = async () => {

    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/symptomsPendingCount')
      const data = await response.json()
      // console.log(data)
      SetSintomasCount(data)
    } catch (error) {

      //console.log(error)
      console.log(error.message)
    }

  }

  const handleValidatePrimeraOK = async (sym_id) => {
    //e.preventDefault();

    const confirmed = window.confirm("¿Estás seguro de que deseas realizar pre-verificación de este síntoma?");
    if (!confirmed) {
      return; // Si el usuario cancela, no ejecuta el delete
    }

    try {
      //borra la id seleccionada en el back end
      const resp = await fetch(
        process.env.REACT_APP_API_URL + `/symptomsValidateFirst?sym_id=${sym_id}`,
        {
          method: "POST",
          mode: "cors"
        }
      );

      console.log(resp);
    } catch (error) {
      console.log(error);
    }
  };

  const handleValidateSegundaOK = async (sym_id) => {
    //e.preventDefault();

    const confirmed = window.confirm("¿Estás seguro de que deseas realizar la verificación de este síntoma?");
    if (!confirmed) {
      return; // Si el usuario cancela, no ejecuta el delete
    }

    SetSintomas(sintomas.filter(sintoma => sintoma.sym_id !== sym_id))

    try {
      //borra la id seleccionada en el back end
      const resp = await fetch(
        process.env.REACT_APP_API_URL + `/symptomsValidateSecond?sym_id=${sym_id}`,
        {
          method: "POST",
          mode: "cors"
        }
      );

      console.log(resp);
    } catch (error) {
      console.log(error);
    }
  };

  //recibe parametro para BORRAR la id seleccionada en el backend
  const handleDelete = async (id) => {
    const confirmed = window.confirm("¿Estás seguro de que deseas eliminar este síntoma?");

    if (!confirmed) {
      // Si el usuario cancela no sigue la funcion
      return;
    }


    //chequeo si el sintoma no tiene relaciones patologia-sintoma

    let patologiasrelacionadascount = { count: 0 }; // Inicializa el objeto

    try {

      const response = await fetch(process.env.REACT_APP_API_URL + `/pathologiesSearchbyonesymptomCount?sym_id_1=${id}`)
      const data = await response.json()

      patologiasrelacionadascount = data[0];

    } catch (error) {
      console.log(error.message)
    }

    if (patologiasrelacionadascount.count > 0) {
      //MOSTRAR ALERTA
      //alert("No se puede borrar el síntoma porque esta relacionado con " + patologiasrelacionadascount.count + "  patologías.");

      setSnackbar({
        open: true,
        message: `Error: No se puede borrar el síntoma porque esta relacionado con ${patologiasrelacionadascount.count} patologías.`,
        severity: 'error',
      });
      return; // Si el usuario cancela, no ejecuta el delete
    }

    SetSintomas(sintomas.filter(sintoma => sintoma.sym_id !== id))

    try {
      const resp = await fetch(process.env.REACT_APP_API_URL + `/symptomsDelete?id=${id}`, {
        method: "POST",
        mode: "cors"
      });
      console.log(resp);
    } catch (error) {
      console.log(error);
    }


    //ahora tambien borro los idiomas para ese sintoma de la tabla symptoms_translations

    try {
      //borra los registros de idioma de la id seleccionada en el back end
      const resp = await fetch(process.env.REACT_APP_API_URL + `/symptomstranslationsdeleteone?id=${id}`, {
        method: "POST",
        mode: "cors"
      })

      //devuelve status = 2o4 si sale bien no un json
      //const data = await resp.json();
      //console.log(data);
      console.log(resp);

    } catch (error) {
      console.log(error)
    }

  };


  useEffect(() => {
    if (usuario.type === '') {
      navigate("/");
    }
    loadSymptomsCountPending()
    loadSymptomsPending()

  }, [])


  return (
    <>
      <h2>Listado de Síntomas pendientes de verificar del sistema</h2>

      <h3>Mostrando {(sintomascount.count !== '0') ? sintomascount[0].count : '0'} registros</h3>

      {

        (sintomas.length !== 0) ?

          sintomas.map(sintoma => (
            <Card
              style={
                {
                  marginBottom: '.7rem',
                  backgroundColor: sintoma.state === 'pending' ? "#bdbdbd" : "#9b9b9b"
                }
              }
              key={sintoma.sym_id}
            >
              <CardContent style={{
                display: 'flex',
                justifyContent: 'space-between'
              }}>

                <Grid container spacing={2}>
                  <Grid item xs={6}>

                    <div style={{ color: 'black' }}>
                      <Typography variant='h6' >{sintoma.name}</Typography>
                      <Typography>{'SYM: ' + sintoma.sym_id}</Typography>
                      <Typography>{'HPO/RDC: ' + sintoma.hpo_id}</Typography>
                      <Typography>{'Sinónimo: ' + sintoma.synonymous}</Typography>
                      <Typography>{"Estado del sintoma: " + sintoma.state}</Typography>
                      <Typography>{'Creado por: ' + sintoma.username}</Typography>
                    </div>

                  </Grid>
                  <Grid item xs={6}>
                    <div>
                      
                        <>
                          <Button
                            variant="contained"
                            color="success"
                            style={{
                              marginLeft: '.5rem',
                              marginBottom: '.5rem'
                            }}
                            onClick={() => handleValidatePrimeraOK(sintoma.sym_id)}
                          >
                            preverify
                          </Button>

                          <Button
                            variant="contained"
                            color="success"
                            style={{
                              marginLeft: '.5rem',
                              marginBottom: '.5rem'
                            }}
                            onClick={() => handleValidateSegundaOK(sintoma.sym_id)}
                          >
                            verify
                          </Button>
                        </>
                        

                      <Button
                        variant="contained"
                        color="inherit"
                        //onClick={() => console.log('Editando')}
                        style={{
                          marginLeft: '.5rem',
                          marginBottom: '.5rem'
                        }} // Separación inferior
                        onClick={() => navigate(`/symptoms/${sintoma.sym_id}/edit`)}
                      >
                        Editar
                      </Button>
                      <Button
                        variant="contained"
                        color="inherit"
                        //onClick={() => console.log('Editando')}
                        onClick={() => navigate(`/categories_symptoms/${sintoma.sym_id}/${sintoma.name}`)}
                        style={{
                          marginLeft: '.5rem',
                          marginBottom: '.5rem'
                        }}
                      >
                        Categorías asociadas
                      </Button>

                      <Button
                        variant="contained"
                        color="inherit"
                        //onClick={() => console.log('Editando')}
                        onClick={() => navigate(`/pathologies/fromonesymptom/${sintoma.sym_id}`)}
                        style={{
                          marginLeft: '.5rem',
                          marginBottom: '.5rem'
                        }}
                      >
                        Patologías asociadas
                      </Button>


                      <Button
                        variant="contained"
                        color="warning"
                        //onClick={() => console.log('Borrando')}
                        onClick={() => handleDelete(sintoma.sym_id)}
                        style={{
                          marginLeft: '.5rem',
                          marginBottom: '.5rem'
                        }}
                      >
                        Borrar
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card >
          )

          )

          :
          <Grid container spacing={3}>
            <Grid item xs={1} >

            </Grid>

            <Grid item xs={10} >
              <h4>No hay resultados para la búsqueda.</h4>
            </Grid>

            <Grid item xs={1} >

            </Grid>
          </Grid>

      }

      {/* Snackbar para notificaciones */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>



    </>
  )
}
