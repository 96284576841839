
import { useEffect } from 'react';


export default function Bienvenida({ loginOut }) {

    useEffect(() => {
        loginOut()
    })


    return (

        <div>

            <h3>Bienvenido al sistema de administración del RDBot.</h3>
            <h3>Debe iniciar sesión para acceder.</h3>

        </div>

    )
}