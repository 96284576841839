import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Bienvenida from './components/Bienvenida'
import LoginForm from './components/LoginForm'

import PathologiesList from './components/PathologiesList'
import PathologiesListPending from './components/PathologiesListPending'
import PathologiesSearch from './components/PathologiesSearch'
import PathologiesFromOneSymptom from './components/PathologiesFromOneSymptom'
import PathologiesForm from './components/PathologiesForm'

import SymptomsList from './components/SymptomsList'
import SymptomsListPending from './components/SymptomsListPending'
import SymptomsListCategoryPending from './components/SymptomsListCategoryPending'
import SymptomsSearch from './components/SymptomsSearch'
import SymptomsForm from './components/SymptomsForm'

import CategoriesList from './components/CategoriesList'
import CategoriesListPending from './components/CategoriesListPending'
import CategoriesSearch from './components/CategoriesSearch'
import CategoriesForm from './components/CategoriesForm'
import CategoriesSubSystemForm from './components/CategoriesSubSystemForm'

import PathologiesSymptomsList from './components/PathologiesSymptomsList'
import PathologiesSymptomsForm from './components/PathologiesSymptomsForm'
import PathologiesSymptomsListPending from './components/PathologiesSymptomsListPending'

import CategoriesOfSymptomsList from './components/CategoriesOfSymptomsList'

import CategoriesOfCategoryList from './components/CategoriesOfCategoryList'
import CategoriesOfCategoryListPending from './components/CategoriesOfCategoryListPending'

import SimulationCases from './components/SimulationCases'

import NavBar from './components/NavBar'
import Home from './components/Home'

import { Container } from '@mui/material'

import { useState } from 'react';

//import { useNavigate } from 'react-router-dom'

export default function Index() {

 // const navigate = useNavigate();

 const [usuario, SetUsuario] = useState({
  'username': '',
  'password': '',
  'type': ''
});

  const [logedin, setLogedin] = useState(false)

  const loginOk = () => {
    setLogedin(true);
  }

  const loginOut = () => {
    setLogedin(false);
  }

  return (
    <BrowserRouter>

      <NavBar logedin={logedin} loginOut={loginOut} usuario={usuario} />

      <Container>
        <Routes>

        <Route path='/' element={<Bienvenida loginOut={loginOut} />} />

          <Route path='/login/' element={<LoginForm loginOk={loginOk} usuario={usuario} SetUsuario={SetUsuario} />} />

          <Route path='/home/' element={<Home usuario={usuario}  />} />

          <Route path='/pathologies/' element={<PathologiesList  usuario={usuario} />} />
          <Route path='/pathologiesPending/' element={<PathologiesListPending  usuario={usuario} />} />
          <Route path='/pathologies/search' element={<PathologiesSearch  usuario={usuario} />} />
          <Route path='/pathologies/fromonesymptom/:sym_id' element={<PathologiesFromOneSymptom  usuario={usuario} />} />

          <Route path='/pathologies/new' element={<PathologiesForm  usuario={usuario} />} />
          <Route path='/pathologies/:id/edit' element={<PathologiesForm  usuario={usuario} />} />

          <Route path='/symptoms/' element={<SymptomsList  usuario={usuario} />} />
          <Route path='/symptoms/pending' element={<SymptomsListPending  usuario={usuario} />} />
          <Route path='/symptoms/categorypending' element={<SymptomsListCategoryPending  usuario={usuario} />} />
          <Route path='/symptoms/search' element={<SymptomsSearch  usuario={usuario} />} />

          <Route path='/symptoms/new' element={<SymptomsForm  usuario={usuario} />} />
          <Route path='/symptoms/new/:pat/:name/create' element={<SymptomsForm  usuario={usuario} />} />
          <Route path='/symptoms/:id/edit' element={<SymptomsForm  usuario={usuario} />} />


          <Route path='/categories/' element={<CategoriesList  usuario={usuario} />} />
          <Route path='/categoriesPending/' element={<CategoriesListPending  usuario={usuario} />} />
          <Route path='/categories/search' element={<CategoriesSearch  usuario={usuario} />} />

          <Route path='/categories/new' element={<CategoriesForm  usuario={usuario} />} />
          <Route path='/categories/subsystemnew' element={<CategoriesSubSystemForm  usuario={usuario} />} />
          <Route path='/categories/:id/edit' element={<CategoriesForm  usuario={usuario} />} />

          <Route path='/pathologies_symptoms/:id/:name' element={< PathologiesSymptomsList  usuario={usuario} />} />
          <Route path='/pathologies_symptoms/:pat_id/:sym_id/edit' element={< PathologiesSymptomsForm  usuario={usuario} />} />

          <Route path='/PathologiesSymptomsListPending/' element={< PathologiesSymptomsListPending  usuario={usuario} />} />

          <Route path='/categories_symptoms/:id/:name' element={< CategoriesOfSymptomsList  usuario={usuario} />} />

          <Route path='/categories_category/:id' element={< CategoriesOfCategoryList  usuario={usuario} />} />

          <Route path='/categories_categoryPending/' element={< CategoriesOfCategoryListPending  usuario={usuario} />} />

          <Route path='/simulation_cases/' element={< SimulationCases  usuario={usuario} />} />

        </Routes>
      </Container>
    </BrowserRouter>
  )
}
