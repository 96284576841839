import { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import {
    Grid,
    Card,
    Typography,
    CardContent,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Snackbar,
    Alert
} from '@mui/material';

export default function CategoriesOfSymptomsList({ usuario }) {
    const params = useParams();
    const navigate = useNavigate();

    const [categorias, SetCategorias] = useState([]);
    const [busquedadecategorias, SetBusquedaDeCategorias] = useState([]);
    const [palabraparabuscar, SetPalabraParaBuscar] = useState({
        'palabra': ''
    });

    const [categoriasSystemlistbox, SetCategoriasSystemListBox] = useState([]);
    const [subcategoriaslistbox, SetSubCategoriasListBox] = useState([]);

    const [datosseleccionados, SetDatosSeleccionados] = useState({
        'categoria': '',
        'subcategoria': '',
        'cat_id_1': '',
        'name_1': '',
        'type_1': '',
        'cat_id_2': '',
        'name_2': '',
        'type_2': ''
    });

    // Estado para el Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 'error', 'warning', 'info', 'success'
    });

    // Función para cerrar el Snackbar
    const handleCloseSnackbar = () => {
        setSnackbar(prev => ({ ...prev, open: false }));
    };

    // Monitorear cambios en datosseleccionados para depuración
    useEffect(() => {
        console.log("datosseleccionados actualizado:", datosseleccionados);
    }, [datosseleccionados]);

    const handleOnChange = e => {
        SetPalabraParaBuscar({ ...palabraparabuscar, [e.target.name]: e.target.value });
    };

    // PIDo los datos al backend para las categorías actuales del síntoma
    const loadTheCategoriesOfSymptoms = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/cat_sym?id=${params.id}`);
            if (!response.ok) {
                throw new Error(`Error fetching categories: ${response.statusText}`);
            }
            const data = await response.json();
            console.log("Categorías actuales:", data);
            SetCategorias(data);
        } catch (error) {
            console.log("Error en loadTheCategoriesOfSymptoms:", error);
            setSnackbar({
                open: true,
                message: 'Error al cargar las categorías del síntoma.',
                severity: 'error',
            });
        }
    };

    // Función para agregar categorías y subcategorías al síntoma
    const loadCategoriesToSymptom = async (e) => {
        e.preventDefault();
        console.log("Formulario enviado");
        console.log("Datos Seleccionados:", datosseleccionados);

        try {
            // 1. Verificar e Insertar Categoría
            if (datosseleccionados.cat_id_1) {
                const categoriaExiste = categorias.some(cat => cat.cat_id === datosseleccionados.cat_id_1);
                if (!categoriaExiste) {

                        const responseCat = await fetch(`${process.env.REACT_APP_API_URL}/cat_symAdd`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify({
                                sym_id: params.id,
                                cat_id: datosseleccionados.cat_id_1,
                                username: usuario.username,
                                state: "pending"
                            })
                        });


                    if (responseCat.ok) {
                        const dataCat = await responseCat.json();
                        console.log("Categoría agregada:", dataCat);
                        SetCategorias(prev => [...prev, dataCat]);
                        setSnackbar({
                            open: true,
                            message: 'Categoría agregada exitosamente.',
                            severity: 'success',
                        });
                    } else {
                        const errorTextCat = await responseCat.text();
                        console.error("Error al agregar la categoría:", responseCat.statusText, errorTextCat);
                        setSnackbar({
                            open: true,
                            message: 'Error al agregar la categoría.',
                            severity: 'error',
                        });
                    }
                } else {
                    console.log("La categoría ya está asociada al síntoma.");
                    setSnackbar({
                        open: true,
                        message: 'La categoría ya está asociada al síntoma.',
                        severity: 'info',
                    });
                }
            }

            // 2. Verificar e Insertar Subcategoría
            if (datosseleccionados.cat_id_2) {
                const subcategoriaExiste = categorias.some(cat => cat.cat_id === datosseleccionados.cat_id_2);
                if (!subcategoriaExiste) {

                        const responseSubCat = await fetch(`${process.env.REACT_APP_API_URL}/cat_symAdd`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify({
                                sym_id: params.id,
                                cat_id: datosseleccionados.cat_id_2,
                                username: usuario.username,
                                state: "pending"
                            })
                        });

                    if (responseSubCat.ok) {
                        const dataSubCat = await responseSubCat.json();
                        console.log("Subcategoría agregada:", dataSubCat);
                        SetCategorias(prev => [...prev, dataSubCat]);
                        setSnackbar({
                            open: true,
                            message: 'Subcategoría agregada exitosamente.',
                            severity: 'success',
                        });
                    } else {
                        const errorTextSubCat = await responseSubCat.text();
                        console.error("Error al agregar la subcategoría:", responseSubCat.statusText, errorTextSubCat);
                        setSnackbar({
                            open: true,
                            message: 'Error al agregar la subcategoría.',
                            severity: 'error',
                        });
                    }
                } else {
                    console.log("La subcategoría ya está asociada al síntoma.");
                    setSnackbar({
                        open: true,
                        message: 'La subcategoría ya está asociada al síntoma.',
                        severity: 'info',
                    });
                }
            }

            // 3. Resetear los campos seleccionados después de la inserción
            SetDatosSeleccionados({
                'categoria': '',
                'subcategoria': '',
                'cat_id_1': '',
                'name_1': '',
                'type_1': '',
                'cat_id_2': '',
                'name_2': '',
                'type_2': ''
            });

            // 4. Recargar las categorías desde el backend para asegurarse de que están actualizadas
            await loadTheCategoriesOfSymptoms();

        } catch (error) {
            console.log("Error en loadCategoriesToSymptom:", error);
            setSnackbar({
                open: true,
                message: 'Error inesperado al agregar categorías.',
                severity: 'error',
            });
        }
    };


    // PIDo los datos al backend para la búsqueda
    const loadCategories = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/categoriesSearch?palabra=${palabraparabuscar.palabra}`);
            if (!response.ok) {
                throw new Error(`Error searching categories: ${response.statusText}`);
            }
            const data = await response.json();
            console.log("Resultados de búsqueda:", data);
            SetBusquedaDeCategorias(data);
        } catch (error) {
            console.log("Error en loadCategories:", error.message);
            setSnackbar({
                open: true,
                message: 'Error al realizar la búsqueda.',
                severity: 'error',
            });
        }
    };

    // Manejo de eliminación
    const handleDelete = async (cat_id) => {
        const confirmed = window.confirm("¿Estás seguro de que deseas eliminar esta categoría?");
        if (!confirmed) return;

        // Elimina la categoría seleccionada en el frontend
        SetCategorias(categorias.filter(categoria => categoria.cat_id !== cat_id));

        try {
            const resp = await fetch(`${process.env.REACT_APP_API_URL}/cat_symDelete?cat_id=${cat_id}&sym_id=${params.id}`, {
                method: "POST",
                mode: "cors"
            });

            if (resp.ok) {
                console.log("Categoría eliminada exitosamente.");
                setSnackbar({
                    open: true,
                    message: 'Categoría eliminada exitosamente.',
                    severity: 'success',
                });
            } else {
                const errorText = await resp.text();
                console.error("Error al eliminar la categoría:", resp.statusText, errorText);
                setSnackbar({
                    open: true,
                    message: 'Error al eliminar la categoría.',
                    severity: 'error',
                });
            }
        } catch (error) {
            console.log("Error en handleDelete:", error);
            setSnackbar({
                open: true,
                message: 'Error inesperado al eliminar la categoría.',
                severity: 'error',
            });
        }
    };

    // Manejo de adición desde búsqueda
    const handleAdd = async (sym_id, cat_id, name, type) => {
        // Verificar si la categoría ya está asociada al síntoma
        const categoriaExiste = categorias.some(cat => cat.cat_id === cat_id);
        if (categoriaExiste) {
            setSnackbar({
                open: true,
                message: 'La categoría ya está asociada al síntoma.',
                severity: 'info',
            });
            return;
        }

        const confirmed = window.confirm(`¿Estás seguro de que deseas incorporar la categoría: ${name} al síntoma actual?`);
        if (!confirmed) return;

        try {
            const resp = await fetch(`${process.env.REACT_APP_API_URL}/cat_symAdd`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    cat_id,
                    sym_id,
                    username: usuario.username,
                    state: "pending"
                })
            });

            if (resp.ok) {
                const data = await resp.json();
                console.log("Categoría agregada:", data);
                SetCategorias(prev => [{ sym_id, cat_id, name, type }, ...prev]);
                setSnackbar({
                    open: true,
                    message: 'Categoría agregada exitosamente.',
                    severity: 'success',
                });
            } else {
                const errorText = await resp.text();
                console.error("Error al agregar la categoría:", resp.statusText, errorText);
                setSnackbar({
                    open: true,
                    message: 'Error al agregar la categoría.',
                    severity: 'error',
                });
            }
        } catch (error) {
            console.log("Error en handleAdd:", error);
            setSnackbar({
                open: true,
                message: 'Error inesperado al agregar la categoría.',
                severity: 'error',
            });
        }
    };

    // PIDo los datos al backend para las categorías del sistema
    const buscarCategoriasSystem = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/categories`);
            if (!res.ok) {
                throw new Error(`Error fetching categories: ${res.statusText}`);
            }
            const data = await res.json();
            SetCategoriasSystemListBox(data);
        } catch (error) {
            console.log("Error en buscarCategoriasSystem:", error);
            setSnackbar({
                open: true,
                message: 'Error al cargar las categorías del sistema.',
                severity: 'error',
            });
        }
        console.log("Después de buscarCategoriasSystem:", datosseleccionados);
    };

    // PIDo los datos al backend para las subcategorías de una categoría específica
    const buscarSubCategorias = async (id) => {
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/cat_catSubcategories?id=${id}`);
            if (!res.ok) {
                throw new Error(`Error fetching subcategories: ${res.statusText}`);
            }
            const data = await res.json();
            SetSubCategoriasListBox(data);
        } catch (error) {
            console.log("Error en buscarSubCategorias:", error);
            setSnackbar({
                open: true,
                message: 'Error al cargar las subcategorías.',
                severity: 'error',
            });
        }
        console.log("Después de buscarSubCategorias:", datosseleccionados);
    };

    // Maneja el cambio de categoría
    const handleOnChangecat = (e) => {
        const selectedCatId = e.target.value;
        const selectedCategory = categoriasSystemlistbox.find(cat => cat.cat_id === selectedCatId);

        if (selectedCategory) {
            SetDatosSeleccionados(prev => ({
                ...prev,
                categoria: selectedCatId,
                cat_id_1: selectedCategory.cat_id,
                name_1: selectedCategory.name,
                type_1: selectedCategory.type,
                subcategoria: '',
                cat_id_2: '',
                name_2: '',
                type_2: ''
            }));
        }

        buscarSubCategorias(selectedCatId);
    };

    // Maneja el cambio de subcategoría
    const handleOnChangesubcat = (e) => {
        const selectedSubCatId = e.target.value;
        const selectedSubCategory = subcategoriaslistbox.find(sub => sub.cat_id === selectedSubCatId);

        if (selectedSubCategory) {
            SetDatosSeleccionados(prev => ({
                ...prev,
                subcategoria: selectedSubCatId,
                cat_id_2: selectedSubCategory.cat_id,
                name_2: selectedSubCategory.name,
                type_2: selectedSubCategory.type
            }));
        }
    };

    // Cargar las categorías actuales y las categorías del sistema al montar el componente
    useEffect(() => {
        if (!usuario || usuario.type === '') {
            navigate("/");
        }
        loadTheCategoriesOfSymptoms();
        buscarCategoriasSystem();
    }, [usuario.type, navigate]);

    return (
        <>
            <h3>Agregar nueva categoría al síntoma: {params.name}</h3>

            {/* Formulario para agregar categoría */}
            <form onSubmit={loadCategoriesToSymptom}>
                <Card
                    sx={{ mt: 5, mb: '0.7rem', backgroundColor: '#bdbdbd' }}
                >
                    <CardContent sx={{
                        display: 'block',
                        justifyContent: 'space-between'
                    }}>
                        {/* Selección de Categoría */}
                        <FormControl
                            fullWidth
                            sx={{ marginBottom: '0.9rem' }}
                        >
                            <InputLabel id="Tipo">Seleccione categoría</InputLabel>
                            <Select
                                labelId="Tipo"
                                label='Seleccione categoría'
                                name='categoria'
                                value={datosseleccionados.categoria}
                                onChange={handleOnChangecat}
                                inputprops={{ sx: { color: 'white' } }}
                                inputlabelprops={{ sx: { color: 'white' } }}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            backgroundColor: '#333', // Color de fondo del listbox
                                            color: '#fff', // Color del texto del listbox
                                        },
                                    },
                                }}
                            >
                                {categoriasSystemlistbox.map(categoria => (
                                    <MenuItem
                                        key={categoria.cat_id}
                                        value={categoria.cat_id}
                                        sx={{ color: 'white' }} // Color del texto de cada ítem
                                    >
                                        {categoria.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        {/* Selección de Subcategoría */}
                        <FormControl
                            fullWidth
                            sx={{ marginBottom: '0.9rem' }}
                        >
                            <InputLabel id="subTipo">Seleccione subcategoría</InputLabel>
                            <Select
                                labelId="subTipo"
                                label='Seleccione subcategoría'
                                name='subcategoria'
                                value={datosseleccionados.subcategoria}
                                onChange={handleOnChangesubcat}
                                inputprops={{ sx: { color: 'white' } }}
                                inputlabelprops={{ sx: { color: 'white' } }}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            backgroundColor: '#333', // Color de fondo del listbox
                                            color: '#fff', // Color del texto del listbox
                                        },
                                    },
                                }}
                            >
                                {subcategoriaslistbox.map(subcategoria => (
                                    <MenuItem
                                        key={subcategoria.cat_id}
                                        value={subcategoria.cat_id}
                                        sx={{ color: 'white' }} // Color del texto de cada ítem
                                    >
                                        {subcategoria.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        {/* Botón para agregar categoría y subcategoría */}
                        <Button
                            variant='contained'
                            color='primary'
                            type='submit'
                            disabled={!datosseleccionados.subcategoria}
                            sx={{ marginLeft: '0.5rem' }}
                        >
                            Agregar subcategoría al síntoma (y categoría padre si no está)
                        </Button>
                    </CardContent>
                </Card>
            </form>

            {/* Formulario para búsqueda de categorías */}
            <form onSubmit={loadCategories}>
                <Card
                    sx={{ mt: 5, mb: '0.7rem', backgroundColor: '#bdbdbd' }}
                >
                    <CardContent sx={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                        {/* Campo de búsqueda */}
                        <TextField
                            variant='filled'
                            label='Ingrese una palabra para la búsqueda de categorías o subcategorías'
                            name='palabra'
                            fullWidth
                            onChange={handleOnChange}
                            sx={{ display: 'block', margin: '0.5rem 0' }}
                            inputprops={{ sx: { color: 'white', backgroundColor: '#555' } }} // Fondo oscuro y texto blanco
                            inputlabelprops={{ sx: { color: 'white', textAlign: 'right' } }}
                        />

                        {/* Botón de búsqueda */}
                        <Button
                            variant='contained'
                            color='primary'
                            type='submit'
                            disabled={!palabraparabuscar.palabra}
                            sx={{ marginLeft: '0.5rem' }}
                        >
                            Buscar
                        </Button>
                    </CardContent>
                </Card>
            </form>

            {/* Listado de categorías encontradas */}
            <Grid container spacing={3}>
                <Grid item xs={1} />
                <Grid item xs={10}>
                    <h3>Listado de Categorías encontradas en el sistema para su búsqueda.</h3>
                </Grid>
                <Grid item xs={1} />
            </Grid>

            {busquedadecategorias.length !== 0 ? (
                busquedadecategorias.map(categoria => (
                    <Card
                        key={`${categoria.name}-${categoria.cat_id}`} // Corrección de la clave
                        sx={{ mb: '0.7rem', backgroundColor: '#bdbdbd' }}
                    >
                        <CardContent sx={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                            <div style={{ color: 'white', maxWidth: '640px' }}>
                                <Typography variant='h6'>{categoria.name}</Typography>
                                <Typography>{`Cat Id: ${categoria.cat_id}`}</Typography>
                                <Typography>{`Tipo: ${categoria.type}`}</Typography>
                            </div>
                            <div>
                                <Button
                                    variant="contained"
                                    color="inherit"
                                    onClick={() => handleAdd(params.id, categoria.cat_id, categoria.name, categoria.type)}
                                    disabled={categorias.some(cat => cat.cat_id === categoria.cat_id)} // Deshabilitar si ya existe
                                >
                                    Agregar a síntoma
                                </Button>
                            </div>
                        </CardContent>
                    </Card>
                ))
            ) : (
                <Grid container spacing={3}>
                    <Grid item xs />
                    <Grid item xs={7}>
                        <h4>No hay resultados para la búsqueda.</h4>
                    </Grid>
                    <Grid item xs />
                </Grid>
            )}

            {/* Listado de categorías actuales para el síntoma */}
            <h2>Listado de Categorías para el Síntoma: {params.id} - {params.name}</h2>
            {categorias.length !== 0 ? (
                categorias.map(categoria => (
                    <Card
                        key={categoria.cat_id}
                        sx={{ mb: '0.7rem', backgroundColor: '#1e272e' }}
                    >
                        <CardContent sx={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                            <div style={{ color: 'white' }}>
                                <Typography variant='h6'>{categoria.name}</Typography>
                                <Typography>{`Cat Id: ${categoria.cat_id}`}</Typography>
                                <Typography>{`Tipo: ${categoria.type}`}</Typography>
                            </div>
                            <div>
                                {usuario.type === 'admin' && (
                                    <Button
                                        variant="contained"
                                        color="warning"
                                        onClick={() => handleDelete(categoria.cat_id)}
                                        sx={{ marginLeft: '0.5rem' }}
                                    >
                                        Borrar
                                    </Button>
                                )}
                            </div>
                        </CardContent>
                    </Card>
                ))
            ) : (
                <Grid container spacing={3}>
                    <Grid item xs />
                    <Grid item xs={7}>
                        <h4>No hay cargada ninguna categoría para este síntoma.</h4>
                    </Grid>
                    <Grid item xs />
                </Grid>
            )}

            {/* Snackbar para notificaciones */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>

    )
}
