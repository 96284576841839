import { Button, Box, AppBar, Container, Toolbar, Typography } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'

export default function NavBar({ logedin, loginOut, usuario }) {

  const navigate = useNavigate();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position='static' color='transparent'>
        <Container>
          <Toolbar style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}>


            <div>
              <Typography sx={{ flexGrow: 1 }} variant='h6' >
                {logedin ?
                  <Link to='/home/'
                    style={{ textDecoration: 'none', color: 'white' }}
                  >
                    RDBot Database Backend
                  </Link>
                  : 'RDBot Database Backend'}

              </Typography>
            </div>


            <div></div>


            <div>

              {logedin ? <Button
                variant='contained'
                color='primary'
                onClick={() => navigate('/home/')}
              >
                Home
              </Button> : ''}

              {logedin
                ? < Button
                  variant='contained'
                  color='primary'
                  onClick={() => navigate('/')}
                  style={{
                    marginLeft: '.5rem'
                  }}
                >
                  Cerrar sesion {usuario.username} - {usuario.type}
                </Button>

                : < Button
                  variant='contained'
                  color='primary'
                  onClick={() => navigate('/login/')}
                  style={{
                    marginLeft: '.5rem'
                  }}
                >
                  Iniciar sesion
                </Button>
              }
            </div>
          </Toolbar>

        </Container>

      </AppBar>

    </Box >

  )
}
