
import { Grid, Card, Typography, CardContent, TextField, Button, CircularProgress, Snackbar, Alert } from '@mui/material';

import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

export default function LoginForm({ loginOk, usuario, SetUsuario }) {

    const [cargando, SetCargando] = useState(false);

    const navigate = useNavigate()

    // Estado para el Snackbar
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 'error', 'warning', 'info', 'success'
    });

    // Función para cerrar el Snackbar
    const handleCloseSnackbar = () => {
        setSnackbar(prev => ({ ...prev, open: false }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        SetCargando(true);
    
        try {
            const res = await fetch(process.env.REACT_APP_API_URL + `/login`, {
                method: 'POST',
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    username: usuario.username,
                    password: usuario.password
                })
            });
    
            const data = await res.json();
            console.log(data);
    
            if (data.message) {
                setSnackbar({
                    open: true,
                    message: `Error inesperado: ${data.message}`,
                    severity: 'error',
                });
            } else {
                loginOk();
                SetUsuario(data[0]);
                navigate('/home/');
            }
        } catch (error) {
            setSnackbar({
                open: true,
                message: 'Error de red o servidor.',
                severity: 'error',
            });
        }
    
        SetCargando(false);
    };

    const handleOnChange = e => {
        //  console.log(e.target.name, e.target.value)
        SetUsuario({ ...usuario, [e.target.name]: e.target.value })
        //console.log(logedin)
    }



    return (
        <>
            <Grid container direction={'column'} alignContent={'center'} justifyContent={'center'} >
                <Grid item xs={8}>
                    <Card
                        xs={8}
                        sx={{ mt: 5 }}
                        style={{
                            backgroundColor: '#bdbdbd',
                            padding: '1 rem'
                        }}
                    >
                        <Typography variant='5' textAlign='center' color='black'>
                            Ingrese su nombre de usuario y contraseña

                        </Typography>
                        <CardContent >
                            <form onSubmit={handleSubmit}  >
                                <TextField
                                    variant='filled'
                                    label='Nombre de usuario'
                                    name='username'
                                    value={usuario.username}
                                    onChange={handleOnChange}
                                    sx={{ display: 'block', margin: '.5rem 0' }}
                                    inputprops={{ style: { color: 'white' } }}
                                    inputlabelprops={{ style: { color: 'white' } }}
                                />

                                <TextField
                                    variant='filled'
                                    label='Clave'
                                    type='password'
                                    name='password'
                                    value={usuario.password}
                                    onChange={handleOnChange}
                                    sx={{ display: 'block', margin: '.5rem 0' }}
                                    inputprops={{ style: { color: 'white' } }}
                                    inputlabelprops={{ style: { color: 'white' } }}
                                />

                                <Button
                                    variant='contained'
                                    color='primary'
                                    type='submit'
                                    disabled={!usuario.username || !usuario.password}
                                >
                                    {cargando ? < CircularProgress
                                        color='inherit'
                                        size={24}

                                    /> : 'Ingresar'}

                                </Button>


                            </form>
                        </CardContent>

                    </Card>
                </Grid>
            </Grid>

            {/* Snackbar para notificaciones */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>

        </>

    )
}
