import { useEffect, useState } from "react";
import { Card, Typography, CardContent, TextField, Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function PathologiesSearch({ usuario }) {

    const [patologias, SetPatologias] = useState([])

    const navigate = useNavigate()

    const [palabraparabuscar, SetPalabraParaBuscar] = useState({
        'palabra': ''
    });

    const [codigoorphaparabuscar, SetCodigoOrphaParaBuscar] = useState({
        'orpha_id': ''
    });

    const [codigoomimparabuscar, SetCodigoOmimParaBuscar] = useState({
        'omim_id': ''
    });


    const handleOnChange = e => {
        //  console.log(e.target.name, e.target.value)
        SetPalabraParaBuscar({ ...palabraparabuscar, [e.target.name]: e.target.value })
    }

    const handleOnChange2 = e => {
        //  console.log(e.target.name, e.target.value)
        SetCodigoOrphaParaBuscar({ ...codigoorphaparabuscar, [e.target.name]: e.target.value })
    }


    const handleOnChange3 = e => {
        //  console.log(e.target.name, e.target.value)
        SetCodigoOmimParaBuscar({ ...codigoomimparabuscar, [e.target.name]: e.target.value })
    }


    //PIDO LOS DATOS AL BACKEND
    const loadPathologies = async (e) => {
        e.preventDefault();

        try {

            const response = await fetch(process.env.REACT_APP_API_URL + `/pathologiesSearch?palabra=${palabraparabuscar.palabra}`)
            const data = await response.json()
            console.log(data)

            ///(data.rows.length != 0) then 
            SetPatologias(data)

        }
        catch (error) {
            //res.json({ error: error.message });s
            console.log(error.message);
            //next(error);
        }

    }

    //PIDO LOS DATOS AL BACKEND
    const loadPathologiesByOrpha = async (e) => {
        e.preventDefault();

        try {

            const response = await fetch(process.env.REACT_APP_API_URL + `/pathologiesSearchbyorpha?palabra=${codigoorphaparabuscar.orpha_id}`)
            const data = await response.json()
            console.log(data)

            ///(data.rows.length != 0) then 
            SetPatologias(data)

        }
        catch (error) {
            //res.json({ error: error.message });s
            console.log(error.message);
            //next(error);
        }

    }


    //PIDO LOS DATOS AL BACKEND
    const loadPathologiesByOmim = async (e) => {
        e.preventDefault();

        try {

            const response = await fetch(process.env.REACT_APP_API_URL + `/pathologiesSearchbyomim?palabra=${codigoomimparabuscar.omim_id}`)
            const data = await response.json()
            console.log(data)

            ///(data.rows.length != 0) then 
            SetPatologias(data)

        }
        catch (error) {
            //res.json({ error: error.message });s
            console.log(error.message);
            //next(error);
        }

    }

    //recibe parametro para BORRAR la id seleccionada en el backend
    const handleDelete = async (id) => {

        const confirmed = window.confirm("¿Estás seguro de que deseas eliminar esta patología?");
        if (!confirmed) {
            return; // Si el usuario cancela, no ejecuta el delete
        }

        //borra la id seleccionada en el frontend
        SetPatologias(patologias.filter(patologia => patologia.pat_id !== id))

        try {
            //borra la id seleccionada en el back end
            const resp = await fetch(process.env.REACT_APP_API_URL + `/pathologiesDelete?id=${id}`,
                {
                    method: "POST",
                    mode: "cors"
                }
            )

            //devuelve status = 2o4 si sale bien no un json
            //const data = await resp.json();
            //console.log(data);
            console.log(resp);

        } catch (error) {

            console.log(error)

        }

    }

    useEffect(() => {
        if (usuario.type === '') {
            navigate("/");
        }
    }, [])

    return (

        <div>

            <h3>Buscador de patologías.</h3>
            <h3>Introduzca la palabra o parte de la palabra a buscar:</h3>
            <form onSubmit={loadPathologies}>

                <Card
                    sx={{ mt: 5 }}
                    style={
                        {
                            marginBottom: '.7rem',
                            backgroundColor: '#bdbdbd'
                        }
                    }
                >
                    <CardContent style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>


                        <TextField
                            variant='filled'
                            label='Ingrese una palabra para la búsqueda'
                            name='palabra'
                            //value={'anemia'}
                            fullWidth
                            onChange={handleOnChange}
                            sx={{ display: 'block', margin: '.5rem 0' }}
                            inputprops={{ style: { color: 'white', textAlign: 'right' } }}
                            inputlabelprops={{ style: { color: 'white', textAlign: 'right' } }}
                        />

                        <Button
                            variant='contained'
                            color='primary'
                            type='submit'
                            disabled={!palabraparabuscar.palabra}
                            style={{
                                marginLeft: '.5rem'
                            }}
                        >
                            Buscar
                        </Button>
                    </CardContent>
                </Card >

            </form>

            <Grid container spacing={2}>

                <Grid item xs={6} >

                    <h3>Introduzca el código Orpha:</h3>
                    <form onSubmit={loadPathologiesByOrpha}>

                        <Card
                            sx={{ mt: 5 }}
                            style={
                                {
                                    marginBottom: '.7rem',
                                    backgroundColor: '#bdbdbd',
                                    // minWidth: '400px'
                                }
                            }
                        >
                            <CardContent style={{
                                display: 'flex',
                                justifyContent: 'flex-start'
                            }}>


                                <TextField
                                    variant='filled'
                                    label='Ingrese código Orpha'
                                    name='orpha_id'
                                    //value={'anemia'}
                                    fullWidth
                                    onChange={handleOnChange2}
                                    sx={{
                                        display: 'block',
                                        margin: '.5rem 0',
                                        // maxWidth: '400px', 
                                    }}
                                    inputprops={{ style: { color: 'white', textAlign: 'right' } }}
                                    inputlabelprops={{ style: { color: 'white', textAlign: 'right' } }}
                                />

                                <Button
                                    variant='contained'
                                    color='primary'
                                    type='submit'
                                    disabled={!codigoorphaparabuscar.orpha_id}
                                    style={{
                                        marginLeft: '.5rem'
                                    }}
                                >
                                    Buscar
                                </Button>
                            </CardContent>
                        </Card >

                    </form>
                </Grid>
                <Grid item xs={6} >


                    <h3>Introduzca el código Omim:</h3>
                    <form onSubmit={loadPathologiesByOmim}>

                        <Card
                            sx={{ mt: 5 }}
                            style={
                                {
                                    marginBottom: '.7rem',
                                    backgroundColor: '#bdbdbd',
                                    //  minWidth: '400px'
                                }
                            }
                        >
                            <CardContent style={{
                                display: 'flex',
                                justifyContent: 'space-between',

                            }}>


                                <TextField
                                    variant='filled'
                                    label='Ingrese código Omim'
                                    name='omim_id'
                                    //value={'anemia'}
                                    fullWidth
                                    onChange={handleOnChange3}
                                    sx={{ display: 'block', margin: '.5rem 0' }}
                                    inputprops={{ style: { color: 'white', textAlign: 'right' } }}
                                    inputlabelprops={{ style: { color: 'white', textAlign: 'right' } }}
                                />

                                <Button
                                    variant='contained'
                                    color='primary'
                                    type='submit'
                                    disabled={!codigoomimparabuscar.omim_id}
                                    style={{
                                        marginLeft: '.5rem'
                                    }}
                                >
                                    Buscar
                                </Button>
                            </CardContent>
                        </Card >

                    </form>
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={1} >

                </Grid>

                <Grid item xs={10} textAlign={"center"} >
                    <h3>  Listado de Patologías encontradas en el sistema:</h3>
                </Grid>

                <Grid item xs={1} >

                </Grid>
            </Grid>

            {
                // console.log(patologias.length);

                (patologias.length !== 0) ?

                    patologias.map(patologia => (
                        <Card
                            style={
                                {
                                    marginBottom: '.7rem',
                                    backgroundColor: '#1e272e'
                                }
                            }
                            key={patologia.pat_id}
                        >
                            <CardContent style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}>

                                <Grid container spacing={2}>
                                    <Grid item xs={6}>

                                        <div style={{ color: 'white', maxWidth: '640' }}>
                                            <Typography variant='h6' >{patologia.name}</Typography>
                                            <Typography>{'Pat ID: ' + patologia.pat_id}</Typography>
                                            <Typography>{'Orpha: ' + patologia.orpha_id}</Typography>
                                            <Typography>{'Omim: ' + patologia.omim_id}</Typography>

                                        </div>
                                    </Grid>
                                    <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>

                                        <div>
                                            <Button
                                                variant="contained"
                                                color="inherit"
                                                //onClick={() => console.log('Editando')}
                                                onClick={() => navigate(`/pathologies/${patologia.pat_id}/edit`)}
                                                style={{
                                                    marginLeft: '.5rem',
                                                    marginBottom: '.5rem'
                                                }}
                                            >
                                                Editar
                                            </Button>

                                            <Button
                                                variant="contained"
                                                color="inherit"
                                                //onClick={() => console.log('Editando')}
                                                onClick={() => navigate(`/pathologies_symptoms/${patologia.pat_id}/${patologia.name}`)}
                                                style={{
                                                    marginLeft: '.5rem',
                                                    marginBottom: '.5rem'
                                                }}
                                            >
                                                síntomas asociados
                                            </Button>

                                            {usuario.type === 'admin' ?

                                                <Button
                                                    variant="contained"
                                                    color="warning"
                                                    //onClick={() => console.log('Borrando')}
                                                    onClick={() => handleDelete(patologia.pat_id)}
                                                    style={{
                                                        marginLeft: '.5rem',
                                                        marginBottom: '.5rem'
                                                    }}
                                                >
                                                    Borrar
                                                </Button>
                                                :
                                                null
                                            }

                                        </div>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card >
                    )

                    )

                    :
                    <Grid container spacing={3}>
                        <Grid item xs={1} >

                        </Grid>

                        <Grid item xs={10} textAlign={"center"}>
                            <h4>No hay resultados para la búsqueda.</h4>
                        </Grid>

                        <Grid item xs={1} >

                        </Grid>
                    </Grid>




            }


        </div>

    )
}