import { Link, useNavigate } from 'react-router-dom'
import { useEffect } from "react";

export default function Home({ usuario }) {

    const navigate = useNavigate();

    useEffect(() => {
        if (usuario.type === '') {
            navigate("/");
        }
    }, [usuario, navigate]);

    return (

        <div>

            <h3>Bienvenido al sistema de administración del RDBot.</h3>
            <h3>Seleccione la opción deseada:</h3>
            <hr></hr>
            <h3> Patologías (con sus síntomas asociados):
                <br></br> <Link
                    to='/pathologies/'
                    style={{
                        //textDecoration: 'none', 
                        color: 'white'
                    }}
                >
                    - Listar Todas las Patologías </Link>
                <br></br>

                {(usuario.type == 'admin') ?
                    <Link
                        to='/pathologiesPending/'
                        style={{
                            //textDecoration: 'none', 
                            color: 'white'
                        }}
                    >
                        - Listar Todas las Patologías NUEVAS (pendientes de verificar)  </Link>

                    :
                    ''
                }
                <br></br>
                <Link
                    to='/pathologies/search/'
                    style={{
                        // textDecoration: 'none', 
                        color: 'white'
                    }}
                >
                    - Buscar Patologías por Palabra o código Orpha-Omin</Link>
                <br></br>

                {(usuario.type == 'admin') ?
                    <Link
                        to='/pathologies/new/'
                        style={{
                            // textDecoration: 'none', 
                            color: 'white'
                        }}
                    >
                        - Agregar Nueva Patología</Link>
                    :
                    'Usuario sin permisos para crear Patologías'
                }


            </h3>
            <hr></hr>
            <h3>  Síntomas (con sus categorías asociadas):
                <br></br>
                <Link
                    to='/symptoms/'
                    style={{
                        //textDecoration: 'none', 
                        color: 'white'
                    }}
                >- Listar Todos los Síntomas "verificados"</Link>
                <br></br>
                {(usuario.type == 'admin') ?
                    <>
                        <Link
                            to='/symptoms/pending/'
                            style={{
                                //textDecoration: 'none', 
                                color: 'white'
                            }}
                        >- Listar Síntomas NUEVOS (pendientes de verificar)</Link>
                    </>
                    :
                    ' '
                }
                <br></br>
                {(usuario.type == 'admin') ?
                    <>
                        <Link
                            to='/symptoms/categorypending/'
                            style={{
                                //textDecoration: 'none', 
                                color: 'white'
                            }}
                        >- Listar Síntomas SIN CATEGORIA ASIGNADA</Link>
                        <br></br>
                    </>
                    :
                    ' '
                }
                <Link
                    to='/symptoms/search/'
                    style={{
                        // textDecoration: 'none', 
                        color: 'white'
                    }}
                >
                    - Buscar Síntomas por Palabra o código HPO/RDC
                </Link>
                <br></br>
                {(usuario.type == 'admin') ?
                    <Link
                        to='/symptoms/new/'
                        style={{
                            // textDecoration: 'none', 
                            color: 'white'
                        }}
                    >
                        - Agregar Nuevo Síntoma </Link>
                    :
                    'Usuario sin permisos para crear Síntomas'
                }



            </h3>
            <hr></hr>
            <h3>  Relaciones Patología-Síntoma:
                <br></br>
                {(usuario.type == 'admin') ?
                    <Link
                        to='/PathologiesSymptomsListPending/'
                        style={{
                            //textDecoration: 'none', 
                            color: 'white'
                        }}
                    >
                        - Listar Todas las relaciones pat-sym NUEVAS (pendientes de verificar)  </Link>

                    :
                    'Usuario sin permisos para validar relaciones'
                }
            </h3>

            <hr></hr>

            <h3>  Categorías y subcategorías:
                <br></br>
                <Link
                    to='/categories/'
                    style={{
                        //textDecoration: 'none', 
                        color: 'white'
                    }}
                >
                    - Listar Todas las Categorías "System"  </Link>
                <br></br>

                {(usuario.type == 'admin') ?
                    <Link
                        to='/categoriesPending/'
                        style={{
                            //textDecoration: 'none', 
                            color: 'white'
                        }}
                    >
                        - Listar Todas las Categorías y SubCategorías NUEVAS (pendientes de verificar)  </Link>

                    :
                    ''
                }
                <br></br>
                <Link
                    to='/categories/search/'
                    style={{
                        // textDecoration: 'none', 
                        color: 'white'
                    }}
                >
                    - Buscar Categorías por Palabra </Link>

                <br></br>

                {(usuario.type == 'admin') ?
                    <Link
                        to='/categories/new/'
                        style={{
                            // textDecoration: 'none', 
                            color: 'white'
                        }}
                    >
                        - Agregar Nueva Categoría al sistema "System o Subsystem"</Link>
                    :
                    'Usuario sin permisos para crear Categorías System'
                }


                <br></br>

                {(usuario.type == 'admin') ?
                    <Link
                        to='/categories/subsystemnew/'
                        style={{
                            // textDecoration: 'none', 
                            color: 'white'
                        }}
                    >
                        - Agregar Nueva Categoría "SubSystem" a una "System"</Link>
                    :
                    'Usuario sin permisos para agregar una Categoría "SubSystem" a una "System"'
                }


            </h3>


            <hr></hr>
            <h3>  Relaciones Categoría de Categoría:
                <br></br>
                {(usuario.type == 'admin') ?
                    <Link
                        to='/categories_categoryPending/'
                        style={{
                            //textDecoration: 'none', 
                            color: 'white'
                        }}
                    >
                        - Listar Todas las relaciones cat-cat NUEVAS (pendientes de verificar)  </Link>

                    :
                    'Usuario sin permisos para validar relaciones'
                }
            </h3>
            <hr></hr>
            <h3>
                <Link
                    to='/simulation_cases/'
                    style={{
                        // textDecoration: 'none', 
                        color: 'white'
                    }}
                >Simulador de búsquedas de patologías por síntomas
                </Link>
            </h3>
            <hr></hr>
            <h3>  Usuario y permisos</h3>

        </div>

    )
}