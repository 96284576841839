import { useEffect, useState } from "react"
import { Button, Card, CardContent, Typography, Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom';

export default function CategoriesListPending({ usuario }) {

  const [categorias, SetCategorias] = useState([])
  const [categoriascount, SetCategoriasCount] = useState({
    'count': '0'
  });
  const navigate = useNavigate();

  //PIDO LOS DATOS AL BACKEND
  const loadCategoriesPending = async () => {

    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/categoriesPending')
      const data = await response.json()
      //console.log(data)
      SetCategorias(data)
    } catch (error) {

      console.log(error)

    }

  }

  //PIDO LOS DATOS AL BACKEND
  const loadCategoriesCountPending  = async () => {

    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/categoriesCountPending')
      const data = await response.json()
      //console.log(data)
      SetCategoriasCount(data)
    } catch (error) {

      console.log(error)

    }

  }


  //recibe parametro para BORRAR la id seleccionada en el backend
  const handleDelete = async (id) => {
    const confirmed = window.confirm("¿Estás seguro de que deseas eliminar esta categoría?");

    if (confirmed) {
      // Si el usuario confirma, se procede con la eliminación en el frontend
      SetCategorias(categorias.filter(categoria => categoria.cat_id !== id));

      try {
        // Se elimina la categoría en el backend
        const resp = await fetch(process.env.REACT_APP_API_URL + `/categoriesDelete?id=${id}`, {
          method: "POST"
        });

        // Si la eliminación es exitosa, muestra la respuesta en consola
        console.log(resp);

      } catch (error) {
        // Si ocurre un error, lo muestra en consola
        console.log(error);
      }
    } else {
      // Si el usuario cancela, no se hace nada
      console.log("Eliminación cancelada");
    }
  };


  useEffect(() => {
    if (usuario.type === '') {
      navigate("/");
  }
    loadCategoriesCountPending()
    loadCategoriesPending()
  }, [])


  return (
    <>
      <h2>Listado de Categorías pendientes de verificar del sistema</h2>

      <h3>Mostrando 20 registros de {(categoriascount.count !== '0') ? categoriascount[0].count : '0'} </h3>


      {

        (categorias.length !== 0) ?


          categorias.map(categoria => (
            <Card
              style={
                {
                  marginBottom: '.7rem',
                  backgroundColor: '#1e272e'
                }
              }
              key={categoria.cat_id}
            >
              <CardContent style={{
                display: 'flex',
                justifyContent: 'space-between'
              }}>
                <div style={{ color: 'white' }}>


                  <Typography variant='h6'>{categoria.name}</Typography>


                  <Typography>{'Cat ID: ' + categoria.cat_id}</Typography>
                  <Typography>{'Tipo: ' + categoria.type}</Typography>

                </div>
                <div>
                  <Button
                    variant="contained"
                    color="inherit"
                    //onClick={() => console.log('Editando')}
                    onClick={() => navigate(`/categories/${categoria.cat_id}/edit`)}
                  >
                    Editar
                  </Button>

                  <Button
                    variant="contained"
                    color="inherit"
                    //onClick={() => console.log('Editando')}
                    onClick={() => navigate(`/categories_category/${categoria.cat_id}`)}
                    style={{
                      marginLeft: '.5rem'
                    }}
                  >
                    Categorías (hijas) asociadas
                  </Button>

                  {usuario.type === 'admin' ?
                    <Button
                      variant="contained"
                      color="warning"
                      //onClick={() => console.log('Borrando')}
                      onClick={() => handleDelete(categoria.cat_id)}
                      style={{
                        marginLeft: '.5rem'
                      }}
                    >
                      Borrar
                    </Button>
                    :
                    null
                  }


                </div>
              </CardContent>
            </Card >
          )

          )

          :
          <Grid container spacing={3}>
            <Grid item xs={1}>

            </Grid>

            <Grid item xs={10} >
              <h4>No hay resultados para la búsqueda.</h4>
            </Grid>

            <Grid item xs={1} >

            </Grid>
          </Grid>

      }

    </>
  )
}
