import { useEffect, useState } from "react"
import { Button, Card, CardContent, Typography, Grid, TextField } from '@mui/material'
import { useParams, useNavigate } from 'react-router-dom';

export default function CategoriesOfCategoryListPending({ usuario }) {

    const params = useParams()
    const navigate = useNavigate();
    const [relacioncatcat, SetRelacionCatCat] = useState([])


    //PIDO LOS DATOS AL BACKEND
    const loadTheCategoriesOfCategoryPending = async () => {

        try {

            const response = await fetch(process.env.REACT_APP_API_URL + `/cat_catPending`)
            const data = await response.json()
            console.log(data)
            SetRelacionCatCat(data)

        } catch (error) {

            console.log(error)

        }

    }

//recibe parametro para VALIDAR LA RELACION en el backend
const handleValidate = async (cat_id_1, cat_id_2) => {
    //e.preventDefault();

    const confirmed = window.confirm("¿Estás seguro de que deseas validar esta relación categoría-categoría?");
    if (!confirmed) {
      return; // Si el usuario cancela, no ejecuta el delete
    }

    SetRelacionCatCat(relacioncatcat.filter((relacion) => !(relacion.cat_id_1 === cat_id_1 && relacion.cat_id_2 === cat_id_2)));

    try {
      //borra la id seleccionada en el back end
      const resp = await fetch(
        process.env.REACT_APP_API_URL + `/cat_catValidate?cat_id_1=${cat_id_1}&cat_id_2=${cat_id_2}`,
        {
          method: "POST",
          mode: "cors"
        }
      );

      console.log(resp);
    } catch (error) {
      console.log(error);
    }
  };



    //recibe parametro para BORRAR la id seleccionada en el backend
    const handleDelete = async (cat_id_1, cat_id_2) => {
        const confirmDelete = window.confirm(
            `¿Estás seguro que deseas eliminar la relación entre las categorías ${cat_id_1} y ${cat_id_2}?`
        );

        if (confirmDelete) {
            // Borra la id seleccionada en el frontend
            SetRelacionCatCat(relacioncatcat.filter(relacion => !(relacion.cat_id_1 === cat_id_1 && relacion.cat_id_2 === cat_id_2)));

            try {
                // Borra la id seleccionada en el back end
                const resp = await fetch(
                    process.env.REACT_APP_API_URL + `/cat_catDelete?cat_id_1=${cat_id_1}&cat_id_2=${cat_id_2}`,
                    {
                        method: "POST",
                        mode: "cors"
                    }
                );

                console.log(resp);
            } catch (error) {
                console.log(error);
            }
        } else {
            console.log("Eliminación cancelada.");
        }
    }



    useEffect(() => {
        if (usuario.type === '') {
            navigate("/");
        }
        loadTheCategoriesOfCategoryPending()
    }, [])


    return (
        <>

            <h2>Listado de relaciones Categoría-Categoría pendientes de verificar</h2>
            {

                (relacioncatcat.length !== 0) ?

                relacioncatcat.map(relacion => (
                        <Card
                            style={
                                {
                                    marginBottom: '.7rem',
                                    backgroundColor: '#1e272e'
                                }
                            }
                            key={`${relacion.cat_id_1}-${relacion.cat_id_2}`}
                        >
                            <CardContent style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}>
                                <div style={{ color: 'white' }}>
                                    <Typography variant='h6'>{'Cat Id 1: ' + relacion.cat_id_1}</Typography>
                                    <Typography>{relacion.name_1}</Typography>
                                    <Typography variant='h6'>{'Cat Id 2: ' + relacion.cat_id_2}</Typography>
                                    <Typography>{relacion.name_2}</Typography>
                                    <Typography>{'Estado: ' + relacion.state}</Typography>

                                </div>



                                <div>

                                    <Button
                                        variant="contained"
                                        color="success"
                                        //onClick={() => console.log('Editando')}
                                        style={{
                                            marginLeft: '.5rem',
                                            marginBottom: '.5rem'
                                        }} // Separación inferior
                                        onClick={() => handleValidate(relacion.cat_id_1, relacion.cat_id_2)}
                                    >
                                        Validar Relación
                                    </Button>

                                    {usuario.type === 'admin' ?
                                        <Button
                                            variant="contained"
                                            color="warning"
                                            // onClick={() => console.log('Borrando')}
                                            onClick={() => handleDelete(relacion.cat_id_1, relacion.cat_id_2)}
                                            style={{
                                                marginLeft: '.5rem'
                                            }}
                                        >
                                            Borrar
                                        </Button>
                                        :
                                        null
                                    }


                                </div>
                            </CardContent>
                        </Card >
                    )

                    )

                    :
                    <Grid container spacing={3}>
                        <Grid item xs >

                        </Grid>

                        <Grid item xs={7} >
                            <h4>No hay cargado ninguna categoría para esta categoría.</h4>
                        </Grid>

                        <Grid item xs >

                        </Grid>
                    </Grid>

            }



        </>
    )
}
