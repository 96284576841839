import { useEffect, useState } from "react";
import { Button, Card, CardContent, Typography, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import TextField from "@mui/material/TextField"; // Importamos TextField para la búsqueda

export default function PathologiesList({ usuario }) {
  const [patologias, SetPatologias] = useState([]);
  const [filteredPatologias, setFilteredPatologias] = useState([]); // Patologías filtradas
  const [patologiascount, SetPatologiasCount] = useState(0); //YA NO SE USA PORQUE PUSE EL PAGINADO
  const [searchTerm, setSearchTerm] = useState(""); // Estado para la búsqueda
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(30); // Número de registros por página
  const navigate = useNavigate();

  // PIDO LOS DATOS AL BACKEND
  const loadPathologies = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + "/pathologies");
      const data = await response.json();
      SetPatologias(data);
      setFilteredPatologias(data); // Inicialmente, todas las patologías están visibles
    } catch (error) {
      console.log(error.message);
    }
  };

  // PIDO EL CONTEO DE DATOS AL BACKEND
  const loadPathologiesCount = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + "/pathologiesCount");
      const data = await response.json();
      SetPatologiasCount(data[0].count);
    } catch (error) {
      console.log(error.message);
    }
  };

  //recibe parametro para BORRAR la id seleccionada en el backend
  const handleDelete = async (id) => {
    //borra la id seleccionada en el frontend

    const confirmed = window.confirm("¿Estás seguro de que deseas eliminar esta patología?");

    if (!confirmed) {
      return; // Si el usuario cancela, no ejecuta el delete
    }

    SetPatologias(patologias.filter(patologia => patologia.pat_id !== id))
    setFilteredPatologias(filteredPatologias.filter(patologia => patologia.pat_id !== id))

    try {
      //borra la id seleccionada en el back end
      const resp = await fetch(process.env.REACT_APP_API_URL + `/pathologiesDelete?id=${id}`,
        {
          method: "POST",
          mode: "cors"
        }
      )

      //devuelve status = 2o4 si sale bien no un json
      //const data = await resp.json();
      //console.log(data);
      console.log(resp);

    } catch (error) {

      console.log(error)

    }

  }


  // Función para manejar el cambio de página
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Función para manejar el cambio en el input de búsqueda
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value); // Actualizamos el estado con el valor ingresado
    filterPatologias(event.target.value); // Filtramos las patologías cada vez que cambie el input
  };

  // Función para filtrar patologías por nombre
  const filterPatologias = (searchTerm) => {
    const filtered = patologias.filter((patologia) =>
      patologia.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredPatologias(filtered);
    setCurrentPage(1); // Reinicia la paginación al aplicar el filtro
  };

  // Obtener registros actuales basados en la página seleccionada
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredPatologias.slice(indexOfFirstRecord, indexOfLastRecord);

  useEffect(() => {
    if (usuario.type === '') {
      navigate("/");
  }
    loadPathologiesCount();
    loadPathologies();
  }, []);

  return (
    <>
      <h2>Listado de Patologías del sistema</h2>
      <h3>Mostrando {currentRecords.length} registros de {filteredPatologias.length}</h3>

      {/* Campo de búsqueda */}
      <TextField
        label="Filtrar por palabra..."
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm} // Controla el valor del input
        onChange={handleSearchChange} // Llama a la función para manejar el cambio en el input
        InputProps={{
          style: { color: 'white' }, // Color del texto dentro del input
        }}
        InputLabelProps={{
          style: { color: 'white' }, // Color del label
        }}
        
      />

      {currentRecords.length > 0 ? (
        currentRecords.map((patologia) => (
          <Card
            style={{
              marginBottom: ".7rem",
              backgroundColor: "#1e272e",
            }}
            key={patologia.pat_id}
          >
            <CardContent
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <div style={{ color: "white" }}>
                    <Typography variant="h6">{patologia.name}</Typography>
                    <Typography>{"Pat ID: " + patologia.pat_id}</Typography>
                    <Typography>{"Orpha: " + patologia.orpha_id}</Typography>
                    <Typography>{"Omim: " + patologia.omim_id}</Typography>
                  </div>

                </Grid>
                <Grid item xs={6}  style={{ display: "flex", justifyContent: "flex-end" }}>
                  <div>
                    <Button
                      variant="contained"
                      color="inherit"
                      onClick={() => navigate(`/pathologies/${patologia.pat_id}/edit`)}
                      style={{
                        marginLeft: '.5rem',
                        marginBottom: '.5rem'
                      }}
                    >
                      Editar
                    </Button>
                    <Button
                      variant="contained"
                      color="inherit"
                      onClick={() => navigate(`/pathologies_symptoms/${patologia.pat_id}/${patologia.name}`)}
                      style={{
                        marginLeft: '.5rem',
                        marginBottom: '.5rem'
                      }}
                    >
                      Síntomas asociados
                    </Button>

                    {usuario.type === "administrador" && (
                      <Button
                        variant="contained"
                        color="warning"
                        onClick={() => handleDelete(patologia.pat_id)}
                        style={{
                          marginLeft: '.5rem',
                          marginBottom: '.5rem'
                        }}
                      >
                        Borrar
                      </Button>
                    )}
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={1}></Grid>
          <Grid item xs={10} textAlign={"center"}>
            <h4>No hay resultados para la búsqueda.</h4>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      )}

      {/* Paginación */}
      <Pagination
        count={Math.ceil(filteredPatologias.length / recordsPerPage)}
        page={currentPage}
        onChange={handlePageChange}
        color="primary"
        style={{ marginTop: "1rem", display: "flex", justifyContent: "center" }}
      />
    </>
  );
}
