
import { Grid, Card, Typography, CardContent, TextField, Button, CircularProgress } from '@mui/material';

import { useState, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

export default function PathologiesSymptomsForm({ usuario }) {


  const [relacionpatsym, SetRelacionPatSym] = useState({
    pat_id: "",
    sym_id: "",
    link: "",
    username: ""
  })

  const [cargando, SetCargando] = useState(false);
  const [editando, SetEditando] = useState(false);
  const navigate = useNavigate()
  const params = useParams()

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log('submit')
    //console.log(patologia)

    SetCargando(true)

    if (editando) {


      console.log('Editado')

      //HAGO EL POST Y RECIBO LA RESPUESTA EN RES PARA ACTUALIZAR UNA RELACION PAT-SYM
      try {
        const res = await fetch(process.env.REACT_APP_API_URL + `/pat_symUpdate?id=${params.pat_id, params.sym_id}`,
          {
            method: "POST",
            body: JSON.stringify(relacionpatsym),
            mode: "cors",
            headers: { "Content-Type": "application/json" }
          }
        )
        const data = await res.json()
        console.log(data)
      } catch (error) {

        console.log(error)

      }


    } else {

      //HAGO EL POST Y RECIBO LA RESPUESTA EN RES PARA CREAR UNA relacion
      try {
        const res = await fetch(process.env.REACT_APP_API_URL + `/pat_symAdd?username=${usuario.username}&state=pending`, {
          method: "POST",
          body: JSON.stringify(relacionpatsym),
          mode: "cors",
          headers: { "Content-Type": "application/json" }
        })

        // OBTENGO LOS DATOS REALES DE LA RESPUESTA EL POST ANTERIOR
        const data = await res.json()
        console.log(data)

      } catch (error) {

        console.log(error)

      }


    }



    //TERMINO EL PROCESO 
    SetCargando(false)

    //LO ENVIO AL INICIO 
    navigate('/PathologiesSymptomsListPending/')

  }

  const handleOnChange = e => {
    //  console.log(e.target.name, e.target.value)
    SetRelacionPatSym({ ...relacionpatsym, [e.target.name]: e.target.value })
  }

  //// AGREGO CODIGO PARA CUANDO VENGO A EDITAR USANDO EL MISMO FORM DE CREAR

  const loadRelacionPatSym = async (pat_id, sym_id) => {
    try {
      const res = await fetch(process.env.REACT_APP_API_URL + `/pat_symOne?pat_id=${pat_id}&sym_id=${sym_id}`)
      const data = await res.json()
      console.log(data)
      SetRelacionPatSym({ pat_id: data[0].pat_id, sym_id: data[0].sym_id, link: data[0].link, username: data[0].username })
      SetEditando(true)
    } catch (error) {

      console.log(error)

    }
  }



  useEffect(() => {
    if (usuario.type === '') {
      navigate("/");
    }
    //si viene con algun id..
    if (params.pat_id) {
      console.log('viene con un dato de relacion patologia para editar')
      loadRelacionPatSym(params.pat_id, params.sym_id)
    }


  }, [params.pat_id])




  return (

    <Grid container direction={'column'} alignContent={'center'} justifyContent={'center'} >
      <Grid item xs={3}>
        <Card
          sx={{ mt: 5, width: 400 }}
          style={{
            backgroundColor: '#bdbdbd',
            padding: '1 rem'
          }}
        >
          <Typography variant='h6' textAlign='center' >
            {editando ? "Editar Relación Patología Síntoma" : "Crear Nueva Relación Patología Síntoma"}

          </Typography>
          <CardContent>
            <form onSubmit={handleSubmit}>

              <TextField
                variant="filled"
                label="Código Patología"
                name="pat_id"
                disabled={true}
                value={relacionpatsym.pat_id}
                onChange={handleOnChange}
                sx={{ display: 'block', margin: '.5rem 0' }}
                inputprops={{ style: { color: 'white' } }}
                inputlabelprops={{ style: { color: 'white' } }}
              />
              <TextField
                variant='filled'
                label='Código Sintoma'
                name='sym_id'
                disabled={true}
                value={relacionpatsym.sym_id}
                onChange={handleOnChange}
                sx={{ display: 'block', margin: '.5rem 0' }}
                inputprops={{ style: { color: 'white' } }}
                inputlabelprops={{ style: { color: 'white' } }}
              />
              <TextField
                variant='filled'
                label='Link relacion'
                name='link'
                value={relacionpatsym.link}
                onChange={handleOnChange}
                sx={{ display: 'flex', margin: '.5rem 0' }}
                inputprops={{ style: { color: 'white' } }}
                inputlabelprops={{ style: { color: 'white' } }}
              />
              <TextField
                variant='filled'
                label='Username'
                name='username'
                disabled={true}
                value={relacionpatsym.username}
                onChange={handleOnChange}
                sx={{ display: 'block', margin: '.5rem 0' }}
                inputprops={{ style: { color: 'white' } }}
                inputlabelprops={{ style: { color: 'white' } }}
              />
              <Button
                variant='contained'
                color='primary'
                type='submit'
                disabled={!relacionpatsym.pat_id || !relacionpatsym.sym_id || !relacionpatsym.link || !relacionpatsym.username}
              >
                {cargando ? < CircularProgress
                  color='inherit'
                  size={24}

                /> : 'Guardar'}

              </Button>


            </form>
          </CardContent>

        </Card>
      </Grid>
    </Grid>
  )
}
