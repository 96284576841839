import { useEffect, useState } from "react";
import { Card, Typography, CardContent, TextField, Button, Grid, Snackbar, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function SymptomsSearch({ usuario }) {

    const [sintomas, SetSintomas] = useState([])

    const navigate = useNavigate()

    const [palabraparabuscar, SetPalabraParaBuscar] = useState({
        'palabra': ''
    });

    const [codigohpoparabuscar, SetCodigoHpoParaBuscar] = useState({
        'hpo_id': ''
    });

    // Snackbar state
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: "",
        severity: "info",
    });

    // Función para cerrar el Snackbar
    const handleCloseSnackbar = () => {
        setSnackbar(prev => ({ ...prev, open: false }));
    };


    const handleOnChange = e => {
        //  console.log(e.target.name, e.target.value)
        SetPalabraParaBuscar({ ...palabraparabuscar, [e.target.name]: e.target.value })
    }

    const handleOnChange2 = e => {
        //  console.log(e.target.name, e.target.value)
        SetCodigoHpoParaBuscar({ ...codigohpoparabuscar, [e.target.name]: e.target.value })
    }


    //PIDO LOS DATOS AL BACKEND POR PALABRA
    const loadSymptoms = async (e) => {
        e.preventDefault();

        try {

            const response = await fetch(process.env.REACT_APP_API_URL + `/symptomsSearch?palabra=${palabraparabuscar.palabra}`)
            const data = await response.json()
            console.log(data)

            ///(data.rows.length != 0) then 

            SetSintomas(data)

        }
        catch (error) {
            //res.json({ error: error.message });s
            console.log(error.message);
            //next(error);
        }

    }


    //PIDO LOS DATOS AL BACKEND POR CODIGO HPO
    const loadSymptomsByHpo = async (e) => {
        e.preventDefault();

        try {

            const response = await fetch(process.env.REACT_APP_API_URL + `/symptomsSearchbyhpo?palabra=${codigohpoparabuscar.hpo_id}`)
            const data = await response.json()
            console.log(data)

            ///(data.rows.length != 0) then 

            SetSintomas(data)

        }
        catch (error) {
            //res.json({ error: error.message });s
            console.log(error.message);
            //next(error);
        }

    }


    //recibe parametro para BORRAR la id seleccionada en el backend
    const handleDelete = async (id) => {

        const confirmed = window.confirm("¿Estás seguro de que deseas eliminar este síntoma?");
        if (!confirmed) {
            return; // Si el usuario cancela, no ejecuta el delete
        }


        //chequeo si el sintoma no tiene relaciones patologia-sintoma

        let patologiasrelacionadascount = { count: 0 }; // Inicializa el objeto

        try {
           
            const response = await fetch(process.env.REACT_APP_API_URL + `/pathologiesSearchbyonesymptomCount?sym_id_1=${id}`)
            const data = await response.json()

            patologiasrelacionadascount = data[0];

        } catch (error) {
            console.log(error.message)
        }

        if (patologiasrelacionadascount.count > 0) {
            //MOSTRAR ALERTA
            //  alert("No se puede borrar el síntoma porque esta relacionado con " + patologiasrelacionadascount.count + "  patologías.");
        
            setSnackbar({
                open: true,
                message: `Error: No se puede borrar el síntoma porque esta relacionado con ${patologiasrelacionadascount.count} patologías.`,
                severity: 'error',
            });
            return; // Si el usuario cancela, no ejecuta el delete
        }

        //borra la id seleccionada en el frontend
        SetSintomas(sintomas.filter(sintoma => sintoma.sym_id !== id))

        try {
            //borra la id seleccionada en el back end
            const resp = await fetch(process.env.REACT_APP_API_URL + `/symptomsDelete?id=${id}`, {
                method: "POST",
                mode: "cors"
            })

            //devuelve status = 2o4 si sale bien no un json
            //const data = await resp.json();
            //console.log(data);
            console.log(resp);

        } catch (error) {

            console.log(error)

        }

        //ahora tambien borro los idiomas para ese sintoma de la tabla symptoms_translations

        try {
            //borra los registros de idioma de la id seleccionada en el back end
            const resp = await fetch(process.env.REACT_APP_API_URL + `/symptomstranslationsdeleteone?id=${id}`, {
                method: "POST",
                mode: "cors"
            })

            //devuelve status = 2o4 si sale bien no un json
            //const data = await resp.json();
            //console.log(data);
            console.log(resp);

        } catch (error) {
            console.log(error)
        }

    }

    useEffect(() => {
        if (usuario.type === '') {
            navigate("/");
        }

    }, [])

    return (

        <div>

            <h3>Buscador de sintomas.</h3>
            <Grid container spacing={2}>
                <Grid item xs={6}>

                    <h3>Introduzca la palabra o parte de la palabra a buscar:</h3>
                    <form onSubmit={loadSymptoms}>


                        <Card
                            sx={{ mt: 5 }}
                            style={
                                {
                                    marginBottom: '.7rem',
                                    backgroundColor: '#bdbdbd'
                                }
                            }
                        >
                            <CardContent style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}>


                                <TextField
                                    variant='filled'
                                    label='Ingrese una palabra para la búsqueda'
                                    name='palabra'
                                    //value={'anemia'}
                                    fullWidth
                                    onChange={handleOnChange}
                                    sx={{ display: 'block', margin: '.5rem 0' }}
                                    inputprops={{ style: { color: 'white', textAlign: 'right' } }}
                                    inputlabelprops={{ style: { color: 'white', textAlign: 'right' } }}
                                />

                                <Button
                                    variant='contained'
                                    color='primary'
                                    type='submit'
                                    disabled={!palabraparabuscar.palabra}
                                    style={{
                                        marginLeft: '.5rem'
                                    }}
                                >
                                    Buscar
                                </Button>
                            </CardContent>
                        </Card >

                    </form>

                </Grid>
                <Grid item xs={6}>

                    <h3>Introduzca el codigo HPO/RDC:</h3>
                    <form onSubmit={loadSymptomsByHpo}>

                        <Card
                            sx={{ mt: 5 }}
                            style={
                                {
                                    marginBottom: '.7rem',
                                    backgroundColor: '#bdbdbd'
                                }
                            }
                        >
                            <CardContent style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}>


                                <TextField
                                    variant='filled'
                                    label='Ingrese codigo HPO/RDC'
                                    name='hpo_id'
                                    //value={'anemia'}
                                    fullWidth
                                    onChange={handleOnChange2}
                                    sx={{ display: 'block', margin: '.5rem 0' }}
                                    inputprops={{ style: { color: 'white', textAlign: 'right' } }}
                                    inputlabelprops={{ style: { color: 'white', textAlign: 'right' } }}
                                />

                                <Button
                                    variant='contained'
                                    color='primary'
                                    type='submit'
                                    disabled={!codigohpoparabuscar.hpo_id}
                                    style={{
                                        marginLeft: '.5rem'
                                    }}
                                >
                                    Buscar
                                </Button>
                            </CardContent>
                        </Card >

                    </form>
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs >

                </Grid>

                <Grid item xs={7} textAlign={"center"}>
                    <h3>  Listado de Síntomas encontrados en el sistema:</h3>
                </Grid>

                <Grid item xs >

                </Grid>
            </Grid>

            {
                // console.log(sintomas.length);

                (sintomas.length !== 0) ?

                    sintomas.map(sintoma => (
                        <Card
                            style={
                                {
                                    marginBottom: '.7rem',
                                    backgroundColor: sintoma.state === 'pending' ? "#bdbdbd" : "#1e272e"
                                }
                            }
                            key={sintoma.sym_id}
                        >
                            <CardContent style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}>

                                <Grid container spacing={2}>
                                    <Grid item xs={6}>

                                        <div style={{
                                            color: sintoma.state === 'pending' ? "black" : "white",
                                            maxWidth: '640'
                                        }}>

                                            <Typography variant='h6' >{sintoma.name}</Typography>
                                            <Typography>{'Sinónimo: ' + sintoma.synonymous}</Typography>
                                            <Typography>{'SYM: ' + sintoma.sym_id}</Typography>
                                            <Typography>{'HPO/RDC: ' + sintoma.hpo_id}</Typography>
                                            <Typography>{"Estado del sintoma: " + sintoma.state}</Typography>
                                            <Typography>{'Creado por: ' + sintoma.username}</Typography>

                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>

                                        <div>
                                            <Button
                                                variant="contained"
                                                color="inherit"
                                                style={{
                                                    marginLeft: '.5rem',
                                                    marginBottom: '.5rem'
                                                }}
                                                onClick={() => navigate(`/symptoms/${sintoma.sym_id}/edit`)}
                                            >
                                                Editar
                                            </Button>

                                            <Button
                                                variant="contained"
                                                color="inherit"
                                                //onClick={() => console.log('Editando')}
                                                onClick={() => navigate(`/categories_symptoms/${sintoma.sym_id}/${sintoma.name}`)}
                                                style={{
                                                    marginLeft: '.5rem',
                                                    marginBottom: '.5rem'
                                                }}
                                            >
                                                Categorías asociadas
                                            </Button>

                                            <Button
                                                variant="contained"
                                                color="inherit"
                                                //onClick={() => console.log('Editando')}
                                                onClick={() => navigate(`/pathologies/fromonesymptom/${sintoma.sym_id}`)}
                                                style={{
                                                    marginLeft: '.5rem',
                                                    marginBottom: '.5rem'
                                                }}
                                            >
                                                Patologías asociadas
                                            </Button>

                                            {usuario.type === 'admin' ?
                                                <Button
                                                    variant="contained"
                                                    color="warning"
                                                    // onClick={() => console.log('Borrando')}
                                                    onClick={() => handleDelete(sintoma.sym_id)}
                                                    style={{
                                                        marginLeft: '.5rem',
                                                        marginBottom: '.5rem'
                                                    }}
                                                >
                                                    Borrar
                                                </Button>
                                                :
                                                null
                                            }


                                        </div>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card >
                    )

                    )

                    :
                    <Grid container spacing={3}>
                        <Grid item xs >

                        </Grid>

                        <Grid item xs={7} textAlign={"center"}>
                            <h4>No hay resultados para la búsqueda.</h4>
                        </Grid>

                        <Grid item xs >

                        </Grid>
                    </Grid>




            }

            {/* Snackbar para notificaciones */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>


        </div>

    )
}