import { useEffect, useState, useMemo } from "react";
import {
  Button,
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  TextField,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";

export default function PathologiesSymptomsList({ usuario }) {
  const params = useParams();
  const navigate = useNavigate();

  const [sintomas, setSintomas] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [busquedaDeSintomas, setBusquedaDeSintomas] = useState([]);

  const [palabraParaBuscar, setPalabraParaBuscar] = useState({
    palabra: "",
  });

  const [codigoHpoParaBuscar, setCodigoHpoParaBuscar] = useState({
    hpo_id: "",
  });

  // Snackbar state
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  const handleOnChange = (e) => {
    console.log(e.target.name, e.target.value);

    setPalabraParaBuscar({
      ...palabraParaBuscar,
      [e.target.name]: e.target.value,
    });

    if (codigoHpoParaBuscar.hpo_id === "" && e.target.value === "") {
      setBusquedaDeSintomas([]);
    }
  };

  const handleOnChange2 = (e) => {
    console.log(e.target.name, e.target.value);
    setCodigoHpoParaBuscar({
      ...codigoHpoParaBuscar,
      [e.target.name]: e.target.value,
    });

    if (e.target.value === "" && palabraParaBuscar.palabra === "") {
      setBusquedaDeSintomas([]);
    }
  };

  // Obtener síntomas desde el backend
  const loadTheSymptoms = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/pat_sym?id=${params.id}`
      );
      const data = await response.json();
      console.log(data);
      setSintomas(data);
    } catch (error) {
      console.log(error);
      setSnackbar({
        open: true,
        message: 'Error al cargar los síntomas.',
        severity: 'error',
      });
    }
  };

  // Buscar síntomas por palabra
  const loadSymptoms = async (e) => {
    e.preventDefault();

    setBusquedaDeSintomas([]); // Limpiar resultados anteriores

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/symptomsSearch?palabra=${palabraParaBuscar.palabra}`
      );
      const data = await response.json();
      console.log(data);
      setBusquedaDeSintomas(data);
    } catch (error) {
      console.log(error.message);
      setSnackbar({
        open: true,
        message: 'Error al buscar síntomas.',
        severity: 'error',
      });
    }
  };

  // Buscar síntomas por código HPO
  const loadSymptomsByHpo = async (e) => {
    e.preventDefault();

    setBusquedaDeSintomas([]); // Limpiar resultados anteriores

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/symptomsSearchbyhpo?palabra=${codigoHpoParaBuscar.hpo_id}`
      );
      const data = await response.json();
      console.log(data);
      setBusquedaDeSintomas(data);
    } catch (error) {
      console.log(error.message);
      setSnackbar({
        open: true,
        message: 'Error al buscar síntomas por HPO.',
        severity: 'error',
      });
    }
  };


  const handleValidatePrimeraOK = async (sym_id) => {
    //e.preventDefault();

    const confirmed = window.confirm("¿Estás seguro de que deseas realizar pre-verificación de este síntoma?");
    if (!confirmed) {
      return; // Si el usuario cancela, no ejecuta el delete
    }

    try {
      //actualiza el estado de la id seleccionada en el back end
      const resp = await fetch(
        process.env.REACT_APP_API_URL + `/symptomsValidateFirst?sym_id=${sym_id}`,
        {
          method: "POST",
          mode: "cors"
        }
      );

      console.log(resp);
    } catch (error) {
      console.log(error);
    }
  };

  const handleValidateSegundaOK = async (sym_id) => {
    //e.preventDefault();

    const confirmed = window.confirm("¿Estás seguro de que deseas realizar la verificación de este síntoma?");
    if (!confirmed) {
      return; // Si el usuario cancela, no ejecuta el delete
    }

    //SetSintomas(sintomas.filter(sintoma => sintoma.sym_id !== sym_id))

    try {
      //actualiza el estado de la id seleccionada en el back end
      const resp = await fetch(
        process.env.REACT_APP_API_URL + `/symptomsValidateSecond?sym_id=${sym_id}`,
        {
          method: "POST",
          mode: "cors"
        }
      );

      console.log(resp);
    } catch (error) {
      console.log(error);
    }
  };

  // Eliminar un síntoma
  const handleDelete = async (pat_id, sym_id) => {
    const confirmed = window.confirm("¿Estás seguro de que deseas eliminar este síntoma de esta patología?");
    if (!confirmed) {
      return; // Si el usuario cancela, no ejecuta el delete
    }

    try {
      const resp = await fetch(
        `${process.env.REACT_APP_API_URL}/pat_symDelete?pat_id=${pat_id}&sym_id=${sym_id}`,
        {
          method: "POST",
          mode: "cors"
        }
      );

      if (resp.ok) {
        setSintomas(prev => prev.filter(sintoma => sintoma.sym_id !== sym_id));
        setSnackbar({
          open: true,
          message: 'Síntoma eliminado exitosamente.',
          severity: 'success',
        });
      } else {
        const errorText = await resp.text();
        console.error("Error al eliminar el síntoma:", resp.statusText, errorText);
        setSnackbar({
          open: true,
          message: 'Error al eliminar el síntoma.',
          severity: 'error',
        });
      }
    } catch (error) {
      console.log(error);
      setSnackbar({
        open: true,
        message: 'Error inesperado al eliminar el síntoma.',
        severity: 'error',
      });
    }
  };

  // Agregar un síntoma
  const handleAdd = async (pat_id, sym_id, hpo_id, name, synonymous, state) => {
    const confirmed = window.confirm(`¿Estás seguro de que deseas incorporar el síntoma: ${name} a la patología: ${params.name}?`);
    if (!confirmed) {
      return; // Si el usuario cancela, no ejecuta el add
    }

    try {
      const resp = await fetch(
        `${process.env.REACT_APP_API_URL}/pat_symAdd?pat_id=${pat_id}&sym_id=${sym_id}&username=${usuario.username}&state=pending`,
        {
          method: "POST",
          mode: "cors"
        }
      );

      if (resp.ok) {
        const data = await resp.json();
        console.log(data);
        const newSintoma = {
          pat_id,
          name,
          synonymous,
          sym_id,
          hpo_id,
          state: 'pending'
        };

        setSintomas(prev => {
          // Evitar agregar duplicados
          if (prev.some(sintoma => sintoma.sym_id === sym_id)) {
            return prev;
          }
          return [newSintoma, ...prev];
        });

        setBusquedaDeSintomas(prev => prev.filter(sintoma => sintoma.sym_id !== sym_id));
        setSnackbar({
          open: true,
          message: 'Síntoma agregado exitosamente.',
          severity: 'success',
        });
      } else {
        const errorText = await resp.text();
        console.error("Error al agregar el síntoma:", resp.statusText, errorText);
        setSnackbar({
          open: true,
          message: 'Error al agregar el síntoma.',
          severity: 'error',
        });
      }
    } catch (error) {
      console.log(error);
      setSnackbar({
        open: true,
        message: 'Error inesperado al agregar el síntoma.',
        severity: 'error',
      });
    }
  };

  // Manejar cambios en el campo de búsqueda
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value); // Actualizamos el estado con el valor ingresado
  };

  // Filtrar síntomas basado en el término de búsqueda
  const filteredSintomas = useMemo(() => {
    return sintomas.filter((sintoma) =>
      sintoma.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [sintomas, searchTerm]);

  useEffect(() => {
    if (usuario.type === '') {
      navigate("/");
    }
    loadTheSymptoms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <h3>Agregar nuevo síntoma a esta patología: {params.name}</h3>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <form onSubmit={loadSymptoms}>
            <Card
              sx={{ mt: 5 }}
              style={{
                marginBottom: ".7rem",
                backgroundColor: "#bdbdbd",
              }}
            >
              <CardContent
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <TextField
                  variant="filled"
                  label="Ingrese una palabra para la búsqueda"
                  name="palabra"
                  fullWidth
                  onChange={handleOnChange}
                  sx={{ display: "block", margin: ".5rem 0" }}
                  inputprops={{ style: { color: "white", textAlign: "right" } }}
                  inputlabelprops={{
                    style: { color: "white", textAlign: "right" },
                  }}
                />

                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!palabraParaBuscar.palabra}
                  style={{
                    marginLeft: ".5rem",
                  }}
                >
                  Buscar
                </Button>
              </CardContent>
            </Card>
          </form>
        </Grid>

        <Grid item xs={6}>
          <form onSubmit={loadSymptomsByHpo}>
            <Card
              sx={{ mt: 5 }}
              style={{
                marginBottom: ".7rem",
                backgroundColor: "#bdbdbd",
              }}
            >
              <CardContent
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <TextField
                  variant="filled"
                  label="Ingrese código HPO/RDC"
                  name="hpo_id"
                  fullWidth
                  onChange={handleOnChange2}
                  sx={{ display: "block", margin: ".5rem 0" }}
                  inputprops={{ style: { color: "white", textAlign: "right" } }}
                  inputlabelprops={{
                    style: { color: "white", textAlign: "right" },
                  }}
                />

                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!codigoHpoParaBuscar.hpo_id}
                  style={{
                    marginLeft: ".5rem",
                  }}
                >
                  Buscar
                </Button>
              </CardContent>
            </Card>
          </form>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={1}></Grid>

        <Grid item xs={10} textAlign={"center"}>
          {codigoHpoParaBuscar.hpo_id !== "" || palabraParaBuscar.palabra !== "" ? (
            <h3> Listado de Síntomas para agregar a la patología:</h3>
          ) : (
            <h3> Realice una búsqueda para incorporar un nuevo Síntoma a la patología.</h3>
          )}
        </Grid>

        <Grid item xs={1}></Grid>
      </Grid>

      {busquedaDeSintomas.length !== 0 ? (
        busquedaDeSintomas.map((sintoma) => (
          <Card
            style={{
              marginBottom: ".7rem",
              backgroundColor: "#bdbdbd",
            }}
            key={`${sintoma.sym_id}-${sintoma.name}`} // Asegura la unicidad de la key
          >
            <CardContent
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ color: "white", maxWidth: "640px" }}>
                <Typography variant="h6">{sintoma.name}</Typography>
                <Typography>Sinónimo: {sintoma.synonymous}</Typography>
                <Typography>Código sym_id: {sintoma.sym_id}</Typography>
                <Typography>Código hpo_id: {sintoma.hpo_id}</Typography>
              </div>
              <div>
                <Button
                  variant="contained"
                  color="inherit"
                  onClick={() => handleAdd(params.id, sintoma.sym_id, sintoma.hpo_id, sintoma.name, sintoma.synonymous, sintoma.state)}
                  style={{
                    marginLeft: '.5rem',
                    marginBottom: '.5rem'
                  }}
                >
                  Agregar a patología
                </Button>
              </div>
            </CardContent>
          </Card>
        ))
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={1}></Grid>

          <Grid item xs={10} textAlign={"center"}>
            {codigoHpoParaBuscar.hpo_id !== "" || palabraParaBuscar.palabra !== "" ? (
              <h4>No hay resultados para la búsqueda.</h4>
            ) : (
              ""
            )}
          </Grid>

          <Grid item xs={1}></Grid>
        </Grid>
      )}

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                navigate(`/symptoms/new/${params.id}/${params.name}/create`)
              }
            >
              Crear y Agregar nuevo síntoma a esta patología
            </Button>
          </Box>
        </Grid>
      </Grid>

      <h2>Listado de Síntomas de la patología: {params.name}</h2>

      {/* Campo de búsqueda */}
      <TextField
        label="Filtrar por palabra..."
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm} // Controla el valor del input
        onChange={handleSearchChange} // Llama a la función para manejar el cambio en el input
        InputProps={{
          style: { color: 'white' }, // Color del texto dentro del input
        }}
        InputLabelProps={{
          style: { color: 'white' }, // Color del label
        }}
      />

      {sintomas.length !== 0 ? (
        filteredSintomas.map((sintoma) => (
          <Card
            style={{
              marginBottom: ".7rem",
              backgroundColor: sintoma.state === 'pending' ? "#bdbdbd" : "#1e272e",
            }}
            key={sintoma.sym_id}
          >
            <CardContent
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <div style={{ color: sintoma.state === 'pending' ? "black" : "white" }}>
                    <Typography variant="h6">{sintoma.name}</Typography>
                    <Typography>Sinónimo: {sintoma.synonymous}</Typography>
                    <Typography>Código sym_id: {sintoma.sym_id}</Typography>
                    <Typography>Código hpo_id: {sintoma.hpo_id}</Typography>
                    <Typography>Link referencia: {sintoma.link}</Typography>
                    <Typography>Estado del síntoma: {sintoma.state}</Typography>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div>
                    <Button
                      variant="contained"
                      color="inherit"
                      style={{
                        marginLeft: '.5rem',
                        marginBottom: '.5rem'
                      }}
                      onClick={() => navigate(`/symptoms/${sintoma.sym_id}/edit`)}
                    >
                      Editar
                    </Button>

                    {((sintoma.state === 'pending' || sintoma.state === 'preverified') && usuario.type === 'admin') ?
                      <>
                        <Button
                          variant="contained"
                          color="success"
                          //onClick={() => console.log('Editando')}
                          style={{
                            marginLeft: '.5rem',
                            marginBottom: '.5rem'
                          }} // Separación inferior
                          onClick={() => handleValidatePrimeraOK(sintoma.sym_id)}
                        >
                          preverify
                        </Button>

                        <Button
                          variant="contained"
                          color="success"
                          //onClick={() => console.log('Editando')}
                          style={{
                            marginLeft: '.5rem',
                            marginBottom: '.5rem'
                          }} // Separación inferior
                          onClick={() => handleValidateSegundaOK(sintoma.sym_id)}
                        >
                          verify
                        </Button>

                      </>
                      :

                      ''}
                    <Button
                      variant="contained"
                      color="inherit"
                      onClick={() =>
                        navigate(`/categories_symptoms/${sintoma.sym_id}/${sintoma.name}`)
                      }
                      style={{
                        marginLeft: '.5rem',
                        marginBottom: '.5rem'
                      }}
                    >
                      Categorías asociadas
                    </Button>

                    <Button
                      variant="contained"
                      color="inherit"
                      onClick={() => navigate(`/pathologies/fromonesymptom/${sintoma.sym_id}`)}
                      style={{
                        marginLeft: '.5rem',
                        marginBottom: '.5rem'
                      }}
                    >
                      Patologías asociadas
                    </Button>

                    {usuario.type === 'admin' && (
                      <Button
                        variant="contained"
                        color="warning"
                        onClick={() => handleDelete(params.id, sintoma.sym_id)}
                        style={{
                          marginLeft: '.5rem',
                          marginBottom: '.5rem'
                        }}
                      >
                        Borrar de esta patología
                      </Button>
                    )}
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))
      ) : (
        <Grid container spacing={3}>
          <Grid item xs></Grid>

          <Grid item xs={7}>
            <h4>No hay cargado ningún síntoma para esta patología.</h4>
          </Grid>

          <Grid item xs></Grid>
        </Grid>
      )}

      {/* Snackbar para notificaciones */}
      {snackbar.open && (
        <div
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            backgroundColor: snackbar.severity === 'success' ? 'green' : snackbar.severity === 'error' ? 'red' : 'blue',
            color: 'white',
            padding: '10px',
            borderRadius: '5px',
            zIndex: 1000,
          }}
        >
          {snackbar.message}
          <Button onClick={() => setSnackbar({ ...snackbar, open: false })}>X</Button>
        </div>
      )}
    </>
  );
}
